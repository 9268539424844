import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FintechHeader from '../../components/fintech/FintechHeader';
import FintechDetail from '../../components/fintech/FintechDetail';
import FintechContent from "../../components/fintech/FintechContent";
import HeroThirteen from "../../themes/index13/HeroThirteen";
import PaymentCustomer from "../../components/customer/PaymentCustomer";
import PaymentFeature from "../../components/others/PaymentFeature";
import WorkProcessFive from "../../components/work-process/WorkProcessFive";
import PaymentFeatureTwo from "../../components/others/PaymentFeatureTwo";
import PaymentCounter from "../../components/counter/PaymentCounter";
import PaymentTestimonial from "../../components/testimonial/PaymentTestimonial";
import PaymentNewsletter from "../../components/others/PaymentNewsletter";

// https://www.infopulse.com/industries/financial-services
const Fintech = () => {
  return (
    <Layout>
        <Navbar navDark />
        <HeroThirteen />
        <PaymentCounter />

        {/*<PaymentCustomer />*/}
        {/*<PaymentFeature />*/}
        <WorkProcessFive />
        <PaymentFeatureTwo />
        <PaymentTestimonial />
        {/*<PaymentNewsletter />*/}
        <FooterOne style={{
            background:
                "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }} />
    </Layout>
  );
};

export default Fintech;
