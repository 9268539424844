import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import RetailConsumerHeader from "../../components/retail-consumer/RetailConsumerHeader";
import RetailConsumerDetail from "../../components/retail-consumer/RetailConsumerDetail";
import RetailConsumerContent from "../../components/retail-consumer/RetailConsumerContent";
import HeroFourteen from "../../themes/index14/HeroFourteen";
import CustomerBrand from "../../components/customer/CustomerBrand";
import DigiSerives from "../../components/services/DigiSerives";
import NewsLetterTwo from "../../components/cta/NewsLetterTwo";
import DigiWhyChoose from "../../components/others/DigiWhyChoose";
import DigiWorkProcess from "../../components/work-process/DigiWorkProcess";
import DigiIntegration from "../../components/integration/DigiIntegration";
import DigiBlog from "../../components/blog/DigiBlog";
import DigiContact from "../../components/contact/DigiContact";

const RetailConsumer = () => {
  return (
    <Layout>
      <Navbar />
      <HeroFourteen />
      {/*<CustomerBrand />*/}
      <DigiSerives />
      <NewsLetterTwo />
      <DigiWhyChoose />
      <DigiWorkProcess />
      {/*<DigiIntegration />*/}
      {/*<DigiBlog />*/}
      {/*<DigiContact />*/}
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default RetailConsumer;
