import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';
import BlogPagination from "../blog/BlogPagination";

const AboutLocationsLatestBlog = () => {
  return (
      <>
        <section className='masonary-blog-section ptb-60'>
          <div className='container'>
            <div className='row align-items-center justify-content-between'>
              <div className='col-lg-4 col-md-12'>
                <SectionTitle
                    // subtitle='Blog'
                    title='Our Offices'
                />
              </div>
              {/*<div className='col-lg-7 col-md-12'>*/}
              {/*  <div className='text-start text-lg-end mb-4 mb-lg-0 mb-xl-0'>*/}
              {/*    <Link to='/blogs' className='btn btn-primary'>*/}
              {/*      View All Article*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>

            {/* Replace a in future a with Link*/}

            {/*<div className='row'>*/}
            {/*  <div className='col-lg-4 col-md-6'>*/}
            {/*    <div className='single-article rounded-custom my-3'>*/}
            {/*      <Link to={{  pathname: "https://goo.gl/maps/es7PR4E2BLVyhabA8" }} target="_blank" className='article-img'>*/}
            {/*        <img*/}
            {/*            src='assets/img/locations/USA_305x314.jpg'*/}
            {/*            alt='article'*/}
            {/*            className='img-fluid'*/}
            {/*        />*/}
            {/*      </Link>*/}
            {/*      <div className='article-content p-4'>*/}
            {/*        /!*<div className='article-category mb-4 d-block'>*!/*/}
            {/*        /!*  <Link*!/*/}
            {/*        /!*      to='#'*!/*/}
            {/*        /!*      className='d-inline-block text-dark badge bg-warning-soft'*!/*/}
            {/*        /!*  >*!/*/}
            {/*        /!*    Design*!/*/}
            {/*        /!*  </Link>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/es7PR4E2BLVyhabA8" }} target="_blank">*/}
            {/*          <h2 className='h5 article-title limit-2-line-text'>*/}
            {/*            BOSTON, MA*/}
            {/*          </h2>*/}
            {/*        </Link>*/}
            {/*        <p className='limit-4-line-text'>*/}
            {/*          2 Drydock Avenue <br/>*/}
            {/*          Suite 412 W <br/>*/}
            {/*          Boston, MA 02210 <br/>*/}
            {/*          USA <br/>*/}
            {/*        </p>*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/es7PR4E2BLVyhabA8" }} target="_blank">*/}
            {/*          <div className='d-flex align-items-center pt-4'>*/}
            {/*            /!*<div className='avatar'>*!/*/}
            {/*            /!*  <img*!/*/}
            {/*            /!*      src='assets/img/testimonial/6.jpg'*!/*/}
            {/*            /!*      alt='avatar'*!/*/}
            {/*            /!*      width='40'*!/*/}
            {/*            /!*      className='img-fluid rounded-circle me-3'*!/*/}
            {/*            /!*  />*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className='avatar-info'>*/}
            {/*              <h6 className='mb-0 avatar-name'>View on MAP</h6>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='col-lg-4 col-md-6'>*/}
            {/*    <div className='single-article rounded-custom my-3'>*/}
            {/*      <Link to={{  pathname: "https://goo.gl/maps/YWgEmkdjW6p1JKXp8" }} target="_blank" className='article-img'>*/}
            {/*        <img*/}
            {/*            src='assets/img/locations/Canada_305x314.jpg'*/}
            {/*            alt='article'*/}
            {/*            className='img-fluid'*/}
            {/*        />*/}
            {/*      </Link>*/}
            {/*      <div className='article-content p-4'>*/}
            {/*        /!*<div className='article-category mb-4 d-block'>*!/*/}
            {/*        /!*  <Link*!/*/}
            {/*        /!*      to='#'*!/*/}
            {/*        /!*      className='d-inline-block text-dark badge bg-primary-soft'*!/*/}
            {/*        /!*  >*!/*/}
            {/*        /!*    Customer*!/*/}
            {/*        /!*  </Link>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/YWgEmkdjW6p1JKXp8" }} target="_blank">*/}
            {/*          <h2 className='h5 article-title limit-2-line-text'>*/}
            {/*            OTTAWA*/}
            {/*          </h2>*/}
            {/*        </Link>*/}
            {/*        <p className='limit-4-line-text'>*/}
            {/*          343 Preston Street <br/>*/}
            {/*          12th Floor <br/>*/}
            {/*          Ottawa, ON K1S 1N4 <br/>*/}
            {/*          Canada <br/>*/}
            {/*        </p>*/}

            {/*        <Link to={{  pathname: "https://goo.gl/maps/YWgEmkdjW6p1JKXp8" }} target="_blank">*/}
            {/*          <div className='d-flex align-items-center pt-4'>*/}
            {/*            /!*<div className='avatar'>*!/*/}
            {/*            /!*  <img*!/*/}
            {/*            /!*      src='assets/img/testimonial/1.jpg'*!/*/}
            {/*            /!*      alt='avatar'*!/*/}
            {/*            /!*      width='40'*!/*/}
            {/*            /!*      className='img-fluid rounded-circle me-3'*!/*/}
            {/*            /!*  />*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className='avatar-info'>*/}
            {/*              <h6 className='mb-0 avatar-name'>View on MAP</h6>*/}
            {/*            /!*  <span className='small fw-medium text-muted'>*!/*/}
            {/*            /!*  April 24, 2021*!/*/}
            {/*            /!*</span>*!/*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='col-lg-4 col-md-6'>*/}
            {/*    <div className='single-article rounded-custom my-3'>*/}
            {/*      <Link to={{  pathname: "https://goo.gl/maps/Xtv398dbDGpw7BsGA" }} target="_blank" className='article-img'>*/}
            {/*        <img*/}
            {/*            src='assets/img/locations/Belarus_305x314.jpg'*/}
            {/*            alt='article'*/}
            {/*            className='img-fluid'*/}
            {/*        />*/}
            {/*      </Link>*/}
            {/*      <div className='article-content p-4'>*/}
            {/*        /!*<div className='article-category mb-4 d-block'>*!/*/}
            {/*        /!*  <Link*!/*/}
            {/*        /!*      to='#'*!/*/}
            {/*        /!*      className='d-inline-block text-dark badge bg-danger-soft'*!/*/}
            {/*        /!*  >*!/*/}
            {/*        /!*    Development*!/*/}
            {/*        /!*  </Link>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/Xtv398dbDGpw7BsGA" }} target="_blank">*/}
            {/*          <h2 className='h5 article-title limit-2-line-text'>*/}
            {/*            MINSK*/}
            {/*          </h2>*/}
            {/*        </Link>*/}
            {/*        <p className='limit-4-line-text'>*/}
            {/*          1/1 Academician Kuprevich Street <br/>*/}
            {/*          Suite 112 <br/>*/}
            {/*          220141 Minsk <br/>*/}
            {/*          Belarus <br/>*/}
            {/*        </p>*/}

            {/*        <Link to={{  pathname: "https://goo.gl/maps/Xtv398dbDGpw7BsGA" }} target="_blank">*/}
            {/*          <div className='d-flex align-items-center pt-4'>*/}
            {/*            /!*<div className='avatar'>*!/*/}
            {/*            /!*  <img*!/*/}
            {/*            /!*      src='assets/img/testimonial/3.jpg'*!/*/}
            {/*            /!*      alt='avatar'*!/*/}
            {/*            /!*      width='40'*!/*/}
            {/*            /!*      className='img-fluid rounded-circle me-3'*!/*/}
            {/*            /!*  />*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className='avatar-info'>*/}
            {/*              <h6 className='mb-0 avatar-name'>View on MAP</h6>*/}
            {/*            /!*  <span className='small fw-medium text-muted'>*!/*/}
            {/*            /!*  April 24, 2021*!/*/}
            {/*            /!*</span>*!/*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='col-lg-4 col-md-6'>*/}
            {/*    <div className='single-article rounded-custom my-3'>*/}
            {/*      <Link to={{  pathname: "https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" }} target="_blank" className='article-img'>*/}
            {/*        <img*/}
            {/*            src='assets/img/locations/Bulgaria_305x314.jpg'*/}
            {/*            alt='article'*/}
            {/*            className='img-fluid'*/}
            {/*        />*/}
            {/*      </Link>*/}
            {/*      <div className='article-content p-4'>*/}
            {/*        /!*<div className='article-category mb-4 d-block'>*!/*/}
            {/*        /!*  <Link*!/*/}
            {/*        /!*      to='#'*!/*/}
            {/*        /!*      className='d-inline-block text-dark badge bg-primary-soft'*!/*/}
            {/*        /!*  >*!/*/}
            {/*        /!*    Marketing*!/*/}
            {/*        /!*  </Link>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" }} target="_blank">*/}
            {/*          <h2 className='h5 article-title limit-2-line-text'>*/}
            {/*            Sofia*/}
            {/*          </h2>*/}
            {/*        </Link>*/}
            {/*        <p className='limit-4-line-text'>*/}
            {/*          69 Bulgaria Blvd. <br/>*/}
            {/*          Tower B, fl.12 <br/>*/}
            {/*          1404 Sofia <br/>*/}
            {/*          Bulgaria <br/>*/}
            {/*        </p>*/}

            {/*        <Link to={{  pathname: "https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" }} target="_blank">*/}
            {/*          <div className='d-flex align-items-center pt-4'>*/}
            {/*            /!*<div className='avatar'>*!/*/}
            {/*            /!*  <img*!/*/}
            {/*            /!*      src='assets/img/testimonial/4.jpg'*!/*/}
            {/*            /!*      alt='avatar'*!/*/}
            {/*            /!*      width='40'*!/*/}
            {/*            /!*      className='img-fluid rounded-circle me-3'*!/*/}
            {/*            /!*  />*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className='avatar-info'>*/}
            {/*              <h6 className='mb-0 avatar-name'>View on MAP</h6>*/}
            {/*            /!*  <span className='small fw-medium text-muted'>*!/*/}
            {/*            /!*  May 4, 2021*!/*/}
            {/*            /!*</span>*!/*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='col-lg-4 col-md-6'>*/}
            {/*    <div className='single-article rounded-custom my-3'>*/}
            {/*      <Link to={{  pathname: "https://goo.gl/maps/EVxjtYjczYUBQtWX6" }} target="_blank" className='article-img'>*/}
            {/*        <img*/}
            {/*            src='assets/img/locations/Tbilisi_305x314.jpg'*/}
            {/*            alt='article'*/}
            {/*            className='img-fluid'*/}
            {/*        />*/}
            {/*      </Link>*/}
            {/*      <div className='article-content p-4'>*/}
            {/*        /!*<div className='article-category mb-4 d-block'>*!/*/}
            {/*        /!*  <Link*!/*/}
            {/*        /!*      to='#'*!/*/}
            {/*        /!*      className='d-inline-block text-dark badge bg-warning-soft'*!/*/}
            {/*        /!*  >*!/*/}
            {/*        /!*    UI/UX*!/*/}
            {/*        /!*  </Link>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <Link to={{  pathname: "https://goo.gl/maps/EVxjtYjczYUBQtWX6" }} target="_blank">*/}
            {/*          <h2 className='h5 article-title limit-2-line-text'>*/}
            {/*            Tbilisi*/}
            {/*          </h2>*/}
            {/*        </Link>*/}
            {/*        <p className='limit-4-line-text'>*/}
            {/*          Bidzina Kvernadze Street 10 <br/>*/}
            {/*          InOffice Office Building, Floor 2 <br/>*/}
            {/*          Tbilisi <br/>*/}
            {/*          Georgia <br/>*/}
            {/*        </p>*/}

            {/*        <Link to={{  pathname: "https://goo.gl/maps/EVxjtYjczYUBQtWX6" }} target="_blank">*/}
            {/*          <div className='d-flex align-items-center pt-4'>*/}
            {/*            /!*<div className='avatar'>*!/*/}
            {/*            /!*  <img*!/*/}
            {/*            /!*      src='assets/img/testimonial/5.jpg'*!/*/}
            {/*            /!*      alt='avatar'*!/*/}
            {/*            /!*      width='40'*!/*/}
            {/*            /!*      className='img-fluid rounded-circle me-3'*!/*/}
            {/*            /!*  />*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className='avatar-info'>*/}
            {/*              <h6 className='mb-0 avatar-name'>View on MAP</h6>*/}
            {/*            /!*  <span className='small fw-medium text-muted'>*!/*/}
            {/*            /!*  Jan 24, 2021*!/*/}
            {/*            /!*</span>*!/*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='single-article rounded-custom my-3'>
                  <a href="https://goo.gl/maps/es7PR4E2BLVyhabA8" target="_blank" rel="noreferrer"
                        className='article-img'>
                    <img
                        src='assets/img/locations/USA_305x314.jpg'
                        alt='article'
                        className='img-fluid'
                    />
                  </a>
                  <div className='article-content p-4'>
                    <a href="https://goo.gl/maps/es7PR4E2BLVyhabA8" target="_blank" rel="noreferrer">
                      <h2 className='h5 article-title limit-2-line-text'>
                        BOSTON, MA
                      </h2>
                    </a>
                    <p className='limit-4-line-text'>
                      2 Drydock Avenue <br/>
                      Suite 412 W <br/>
                      Boston, MA 02210 <br/>
                      USA <br/>
                    </p>
                    <a href="https://goo.gl/maps/es7PR4E2BLVyhabA8" target="_blank" rel="noreferrer">
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>View on MAP</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-article rounded-custom my-3'>
                  <a href="https://goo.gl/maps/YWgEmkdjW6p1JKXp8" target="_blank" rel="noreferrer"
                        className='article-img'>
                    <img
                        src='assets/img/locations/Canada_305x314.jpg'
                        alt='article'
                        className='img-fluid'
                    />
                  </a>
                  <div className='article-content p-4'>
                    <a href="https://goo.gl/maps/YWgEmkdjW6p1JKXp8" target="_blank" rel="noreferrer">
                      <h2 className='h5 article-title limit-2-line-text'>
                        OTTAWA
                      </h2>
                    </a>
                    <p className='limit-4-line-text'>
                      343 Preston Street <br/>
                      12th Floor <br/>
                      Ottawa, ON K1S 1N4 <br/>
                      Canada <br/>
                    </p>

                    <a href="https://goo.gl/maps/YWgEmkdjW6p1JKXp8" target="_blank" rel="noreferrer">
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>View on MAP</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-article rounded-custom my-3'>
                  <a href="https://goo.gl/maps/Xtv398dbDGpw7BsGA" target="_blank" rel="noreferrer"
                        className='article-img'>
                    <img
                        src='assets/img/locations/Belarus_305x314.jpg'
                        alt='article'
                        className='img-fluid'
                    />
                  </a>
                  <div className='article-content p-4'>
                    <a href="https://goo.gl/maps/Xtv398dbDGpw7BsGA" target="_blank" rel="noreferrer">
                      <h2 className='h5 article-title limit-2-line-text'>
                        MINSK
                      </h2>
                    </a>
                    <p className='limit-4-line-text'>
                      1/1 Academician Kuprevich Street <br/>
                      Suite 112 <br/>
                      220141 Minsk <br/>
                      Belarus <br/>
                    </p>

                    <a href="https://goo.gl/maps/Xtv398dbDGpw7BsGA" target="_blank" rel="noreferrer">
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>View on MAP</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-article rounded-custom my-3'>
                  <a href="https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" target="_blank" rel="noreferrer"
                        className='article-img'>
                    <img
                        src='assets/img/locations/Bulgaria_305x314.jpg'
                        alt='article'
                        className='img-fluid'
                    />
                  </a>
                  <div className='article-content p-4'>
                    <a href="https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" target="_blank" rel="noreferrer">
                      <h2 className='h5 article-title limit-2-line-text'>
                        Sofia
                      </h2>
                    </a>
                    <p className='limit-4-line-text'>
                      69 Bulgaria Blvd. <br/>
                      Tower B, fl.12 <br/>
                      1404 Sofia <br/>
                      Bulgaria <br/>
                    </p>

                    <a href="https://goo.gl/maps/LDv4gXYEo6Y7MGcG6" target="_blank" rel="noreferrer">
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>View on MAP</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-article rounded-custom my-3'>
                  <a href="https://goo.gl/maps/EVxjtYjczYUBQtWX6" target="_blank" rel="noreferrer"
                        className='article-img'>
                    <img
                        src='assets/img/locations/Tbilisi_305x314.jpg'
                        alt='article'
                        className='img-fluid'
                    />
                  </a>
                  <div className='article-content p-4'>
                    <a href="https://goo.gl/maps/EVxjtYjczYUBQtWX6" target="_blank" rel="noreferrer">
                      <h2 className='h5 article-title limit-2-line-text'>
                        Tbilisi
                      </h2>
                    </a>
                    <p className='limit-4-line-text'>
                      Bidzina Kvernadze Street 10 <br/>
                      InOffice Office Building, Floor 2 <br/>
                      Tbilisi <br/>
                      Georgia <br/>
                    </p>

                    <a href="https://goo.gl/maps/EVxjtYjczYUBQtWX6" target="_blank" rel="noreferrer">
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>View on MAP</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </>
  );
};

export default AboutLocationsLatestBlog;
