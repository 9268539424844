import React from 'react';
import {Link} from "react-router-dom";

export default function NewsLetterTwo() {
  return (
    <section className="digi-news-letter">
      <div className="container">
        <div className="bg-dark text-light rounded-custom p-4 p-md-5 p-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="digi-newsletter">
                {/*<div>*/}
                {/*  <span className="span-arrow text-pink">*/}
                {/*    Best Yout website*/}
                {/*  </span>*/}
                {/*  <img src="assets/img/arro-right.svg" alt="arrow" />*/}
                {/*</div>*/}
                <h2>Are You Ready? Book Appointment Now!</h2>
                <p>
                  If you're looking for a reliable partner for software development, Hireya has over 15 years of experience in the industry,
                  along with a dedicated team of skilled, experienced professionals who understand the intricacies of the retail industry.
                  We’ve worked with over 100 clients around the world, and our expansive portfolio includes more than 2,000 successfully completed projects.
                </p>
                {/*<form className="mt-4">*/}
                {/*  <div className="position-relative digi-news-form">*/}
                    {/*<input*/}
                    {/*  type="text"*/}
                    {/*  className="form-control"*/}
                    {/*  placeholder="Website Url"*/}
                    {/*/>*/}
                    {/*<button className="digi-news-button">*/}
                    {/*  Analysis Webiste*/}
                    {/*</button>*/}
                    {/*<Link to="/contact-us" className="digi-news-button">*/}
                    {/*  Making Appoinment <i className="far fa-arrow-right"></i>*/}
                    {/*</Link>*/}
                {/*  </div>*/}
                {/*</form>*/}

                <div className="col-lg-3">
                  <div className="cyber-cta-btn">
                    <Link to="/contact-us" className="mt-3 btn btn-primary me-auto">
                      Make Appointment <i className="far fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/d-pie.png"
                  className="img-fluid text-end"
                  alt="pie"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
