import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTechnologies = () => {
  return (
    <>
      <section
        className='feature-section ptb-120 bg-light'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Services'
                title='Best Services to Grow Your Business Value'
                description='Some of our web development services
                leadership skills.'
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>

                <div
                  className='feature-card shadow-sm rounded-custom p-5 bg-white'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                      <i className='fal fa-code fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>Web Redesign and Modernization</h3>
                     <p className='mb-0 text-start'>
                       Tired of your old website design? We reconstruct your existing website to improve the visual appeal, functional fullness, and usability. This includes revamping a legacy technology stack, re-architecting to enable greater scalability, and adding new features and innovations.
                     </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>

                <div
                    className='feature-card shadow-sm rounded-custom p-5 bg-white'
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  <div
                      className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-success-light'>
                      <i className='fal fa-books fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>Get a team that grows
                      with your needs</h3>
                    <p className='mb-0 text-start'>
                      We’ll give you a dedicated software development team of full-time engineers, and supplement them with other experts in DevOps, cybersecurity and more as necessary.
                    </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>

                <div
                    className='feature-card shadow-sm rounded-custom p-5 bg-white'
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  <div
                      className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-secondary'>
                      <i className='fal fa-chalkboard-user fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>Tech Consulting</h3>
                    <p className='mb-0 text-start'>
                      Need advice and experience to make the right technical decisions? We will advise you on your web application’s IT architecture, technology stack, and functionality. By using our expertise in creating consumer and business-centric solutions and business intelligence techniques, you have a technology partner on your path to success.
                    </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>

                <div
                    className='feature-card shadow-sm rounded-custom p-5 bg-white'
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  <div
                      className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-info'>
                      <i className='fal fa-square-check fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>API Development and Integration</h3>
                    <p className='mb-0 text-start'>
                      Want to integrate marketing, booking, or geolocation APIs into your app? By appending 3rd party services to your website, we supply a unique solution to solve your business issues. We check the scalability and performance of those services to make sure they fit well within your software.
                    </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>

                <div
                    className='feature-card shadow-sm rounded-custom p-5 bg-white'
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  <div
                      className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-danger'>
                      <i className='fal fa-user-group fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>Meet the market fast</h3>
                    <p className='mb-0 text-start'>
                      Our Agile approach to software development, as well as project delivery experience and flexible team capacity, helps us to launch products as soon as possible by prioritizing features and making continuous releases and improvements.
                    </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>

                <div
                    className='feature-card shadow-sm rounded-custom p-5 bg-white'
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  <div
                      className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    {/*<i className='fal fa-file-chart-line icon-sm text-success'></i>*/}
                  </div>
                  <div className='feature-content'>
                    <div className='feature-icon icon-center pb-5 rounded-custom bg-dark'>
                      <i className='fal fa-file-code fa-2x text-white'></i>
                    </div>
                    <h3 className='h5 text-center'>Back-End Development</h3>
                    <p className='mb-0 text-start'>
                      Smart engineering of secure, intuitive, and high-performing web and mobile products to help you interact with your customers.
                      Our expert developers will ensure your business is integrated to critical information and data using secure API communication. We also provide comprehensive consultation services to find, develop or review your API services.
                    </p>
                  </div>
                  {/*<Link*/}
                  {/*  to='/single-service'*/}
                  {/*  className='link-with-icon text-decoration-none mt-3'*/}
                  {/*>*/}
                  {/*  View Details <i className='far fa-arrow-right'></i>*/}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTechnologies;
