import React from 'react';

const DataAnalyticsFeatureImgContentSix = () => {
  return (
    <>
      <section className='app-two-feature-three bg-light ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-5 col-xl-5 col-sm-12'>
              <div className='app-two-feature-three-left'>
                <div className='app-content-feature-wrap'>
                  <h2>Our four-step methodology</h2>
                  <p>
                    We’ll work closely with you to implement your analytics technology in a way that makes perfect sense for your business, your workforce and your data:
                  </p>
                </div>
                <div
                  className='accordion faq-accordion mt-5'
                  id='accordionExample'
                >
                  <div className='accordion-item active border-0'>
                    <h5 className='accordion-header' id='faq-1'>
                      <button
                        className='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-1'
                        aria-expanded='true'
                      >
                        <i className='far fa-bell pe-3'></i> 01. Design
                      </button>
                    </h5>
                    <div
                      id='collapse-1'
                      className='accordioncollapse collapse show'
                      aria-labelledby='faq-1'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        We work to understand the challenges that you’re facing and, using our past experience, suggest how technological solutions can deliver business value. Our data engineers and data scientists are experienced in investigating issues posed by existing technologies and implementing innovative solutions.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item border-0'>
                    <h5 className='accordion-header' id='faq-2'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-2'
                        aria-expanded='false'
                      >
                        <i className='far fa-command pe-3'></i>
                        02. Proof of concept
                      </button>
                    </h5>
                    <div
                      id='collapse-2'
                      className='accordion-collapse collapse'
                      aria-labelledby='faq-2'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        We demonstrate the proposed new solution and how it will work in practice. Using your real-world data ensures that it meets your needs before moving forward.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item border-0'>
                    <h5 className='accordion-header' id='faq-3'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-3'
                        aria-expanded='false'
                      >
                        <i className='fas fa-link pe-3'></i>
                        03. Delivery
                      </button>
                    </h5>
                    <div
                      id='collapse-3'
                      className='accordion-collapse collapse'
                      aria-labelledby='faq-3'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        We implement a fully operational, scalable solution, integrated within your infrastructure. We use best practice DevOps/MLOps, infrastructure as code, monitoring and automation frameworks to optimise your solution.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item border-0'>
                    <h5 className='accordion-header' id='faq-4'>
                      <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapse-4'
                          aria-expanded='false'
                      >
                        <i className='fas fa-arrow-alt-up pe-3'></i>
                        04. Scaling and embedding
                      </button>
                    </h5>
                    <div
                        id='collapse-4'
                        className='accordion-collapse collapse'
                        aria-labelledby='faq-4'
                        data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        Our expert team of software engineers work with you to help scale your analytics and create a transformed business culture fuelled by innovation and integrated AI.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-sm-12'>
              <div className='app-two-feature-three-img position-relative text-center mt-5 mt-lg-0'>
                <div className='feature-three-shape'>
                  <img
                    src='assets/img/app-two-feature-blob-shape.png'
                    alt='app screen'
                    className='feature-three-blob img-fluid'
                  />
                </div>
                <img
                  src='assets/img/app-two-feature-phone-without-shape.png'
                  alt='app screen'
                  className='position-relative z-5'
                />
                <div className='img-peice d-none d-lg-block'>
                  <img
                    src='assets/img/app-two-file.png'
                    alt='app screen'
                    className='img-one position-absolute custom-shadow'
                  />
                  <img
                    src='assets/img/app-two-video-shape.png'
                    alt='app screen'
                    className='img-two position-absolute custom-shadow'
                  />
                  <img
                    src='assets/img/app-two-chart.png'
                    alt='app screen'
                    className='img-three position-absolute custom-shadow'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataAnalyticsFeatureImgContentSix;
