import React from 'react';
import { Link } from 'react-router-dom';

const CookiePolicyDetail = () => {
  return (
      <>
        <section className='support-content ptb-120'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 col-md-8'>
                <div className='tab-content' id='v-pills-supportContent'>
                  <div
                      className='tab-pane fade show active'
                      id='support-tab-1'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Cookie Policy</h2>
                      <ul className='support-article-list list-unstyled mt-4'>
                        <li className='py-1'>
                          <p>
                            Hireya, Inc., together with its subsidiaries (collectively, “Hireya”), provides this Cookie Policy, which applies to any websites, branded pages
                            on third party platforms, and applications accessed or used through such websites or third party platforms (“Hireya Sites”), which are operated
                            by or on behalf of Hireya.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.2. Description and use of this Website
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            When you visit the Hireya Sites, Hireya uses a number of suppliers who may place cookies, tags, pixels, and similar tracking technologies
                            (collectively, “Cookies”) on your device, browser, or the webpage you are viewing, in order to personalize your experience (e.g., language preferences),
                            understand usage patterns, provide, improve and secure the Hireya Sites.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            Hireya Sites use different types of Cookies for different reasons, as follows:
                          </h4>
                        </li>
                        <ul className='content-list list-unstyled'>
                          <li>
                            Absolutely Necessary Cookies: These Cookies are essential to enable you to move around Hireya Sites
                            and use its features. Without these Cookies, services you have asked for, like adding items to an online shopping cart, cannot be provided.
                          </li>
                          <li>
                            Performance Cookies: These Cookies collect information about how you use Hireya Sites. Information collected includes,
                            for example, browsers and operating systems used, domain name of the website previously visited, number of visits, average
                            duration of visit, and pages viewed. These Cookies do not collect information that personally identifies you and considered
                            aggregated data. Performance Cookies are used to improve the user-friendliness of a website and enhance your experience.
                          </li>
                          <li>
                            Functionality Cookies: These Cookies allow the website to remember choices you make (such as your username or ID, language
                            preference, or the area or region you are in) and provide enhanced, more personal features. These Cookies can also be used to
                            remember changes you have made to text size, fonts, and other customizable parts of web pages. They may also be used to provide
                            services you have asked for, such as watching a video or commenting on a blog. The information these Cookies collect may be anonymized,
                            and they cannot track your browsing activity on other websites.
                          </li>
                          <p>
                            Hireya may also allow third party service performance and retargeting Cookies, such as the Google Analytics, Google reCAPTCHA,
                            Yandex Metrica, LinkedIn Insight Tag, Google AdWords, Facebook Pixel, Amplitude, Hotjar, Visitor Queue, Marketo Munchkin, Reddit, etc.,
                            to collect information about your online activities on the Hireya Sites and across unaffiliated websites you may visit. This information
                            is used to provide ads tailored to your interests (behavioral advertising), and to collect information to help track and manage the
                            effectiveness of the ads and number of visitors. This means Hireya may show ads based on your past visits to Hireya Sites, through publishing
                            networks, such as the Google Content Network (GCN). More information about these Cookies may be available on the relevant third party’s website.
                          </p>
                          <p>
                            If you do not agree to certain uses of Cookies detailed above, you can exercise certain choices through your browser settings or by
                            not using the Hireya Sites. If you choose to reject cookies via your browser settings, you may still use Hireya Sites though your access to
                            some functionality and areas may be restricted. As the means by which you can refuse cookies through your web browser controls vary from
                            browser-to-browser, you should visit your browser’s help menu for more information.
                          </p>
                          <p>
                            You may also learn more about and opt-out of certain tailored ads (behavioral advertising) by visiting the Digital Advertising Alliance-US,
                            Network Advertising Initiative, Digital Advertising Alliance-Canada, European Interactive Digital Advertising Alliance, www.aboutcookies.org,
                            www.allaboutcookies.org or your device settings for our mobile app. You may also edit or opt-out of your Google Display Network ads’ preferences
                            at http://www.google.com/settings/ads/.
                          </p>
                          <p>
                            Do-Not Track:  At this time, Hireya Sites are not configured to honor browsers’ “Do Not Track” signals.
                          </p>
                       </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default CookiePolicyDetail;
