import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const WhoWeWorkWithTabOne = () => {
  return (
    <>
      <section className='feature-tab-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center align-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                // subtitle='Features'
                title='Client case studies &amp; business success stories'
                description='See how we help our clients become the next and best versions of themselves—including behind-the-scenes stories from our people—around the world, each and every day.'
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <ul
                className='nav justify-content-center feature-tab-list-2 mb-0'
                id='nav-tab'
                role='tablist'
              >
                <li className='nav-item'>
                  <Link
                    className='nav-link active'
                    to='#tab-1'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-1'
                    role='tab'
                    aria-selected='false'
                  >
                    Fintech
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-2'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-2'
                    role='tab'
                    aria-selected='false'
                  >
                    Retail &amp; Consumer
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-3'
                    role='tab'
                    aria-selected='false'
                  >
                    eCommerce
                  </Link>
                </li>
              </ul>
              <div className='tab-content' id='nav-tabContent'>
                <div
                  className='tab-pane fade pt-60 active show'
                  id='tab-1'
                  role='tabpanel'
                >
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                      <div className='feature-tab-info'>
                        <h3>Payment Gateway for processing credit cards</h3>
                        <p>
                          <h5 className='text-decoration-none mt-4 d-block'>#FinTech &emsp; #Platform &emsp; #UI/UX Design</h5>
                        </p>
                        <p>
                          Our specialists can develop the custom payment gateway solutions or integrate existing payment systems using the API. Hireya provides a full cycle of payment gateway development in the shortest possible term. We maintain the potential of the gateway functionality expansion so that you can meet your changing business necessities. Responding to the needs of our clients, we develop software that will be convenient to be used among the enterprise clients, users and companies of any type.
                        </p>
                        <p>
                          In the meantime, ensuring the complete security of users’ confidential data is a significant aspect of the software development of the payment system as well. Due to this, our developmental process involves the adopting of the advanced security technologies and complies with the payment security standards and requirements, such as EMV, PA-DSS, PCI DSS. Moreover, built-in fraud detection systems will protect your customers and you from the threats of questionable transactions, for instance, using automatic card numbers.
                        </p>
                        <Link
                          to='/case-study-fintech'
                          className='read-more-link text-decoration-none mt-4 d-block'
                        >
                          Read the case study
                          <i className='far fa-arrow-right ms-2'></i>
                        </Link>
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <img
                        src='assets/img/screen/widget-12.png'
                        alt='feature tab'
                        className='img-fluid mt-4 mt-lg-0 mt-xl-0'
                      />
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade pt-60' id='tab-2' role='tabpanel'>
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                      <img
                        src='assets/img/screen/widget-8.png'
                        alt='feature tab'
                        className='img-fluid mb-4 mb-lg-0 mb-xl-0'
                      />
                    </div>
                    <div className='col-lg-5'>
                      <div className='feature-tab-info'>
                        <h3>Shipping and Logistics</h3>
                        <p>
                          <h5 className='text-decoration-none mt-4 d-block'>#Retail &emsp; #Platform</h5>
                        </p>
                        <p>
                          Development of custom order management portal for ecommerce, WMS connectors and ERP data synchronization and workflow capabilities
                        </p>
                        <p>
                          We provide business software development outsourcing and support services for Retail companies across the spectrum: retailers, internet / eCommerce retailers, catalog and mail order companies, consumer product companies, direct response marketers, loyalty program companies, grocery store chains, hypermarkets, luxury retailers, multi-brand outlets, general merchandise stores, specialty stores, department stores, warehouse retailers, discount retailers, chain stores, multi-national retailers, and others.
                        </p>
                        <Link
                          to='/case-study-retail-and-consumer'
                          className='read-more-link text-decoration-none mt-4 d-block'
                        >
                          Read the case study
                          <i className='far fa-arrow-right ms-2'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade pt-60' id='tab-3' role='tabpanel'>
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                      <div className='feature-tab-info'>
                        <h3>eCommerce Website Design</h3>
                        <p>
                          <h5 className='text-decoration-none mt-4 d-block'>#eCommerce &emsp; #UI/UX Design</h5>
                        </p>
                        <p>
                          eCommerce is here to stay, but businesses need to make a solid impression on mind of buyers and capture the market. A website with smooth UI, right design elements, catchy theme, and robust platform is what you need to stay at the top. Our web design team has experience and expertise to design eCommerce website that’s just right for your business.
                        </p>
                        <p>
                          Starting from the analysis of the specifics of your business and ending up with the launching coupled with supporting turnkey solutions, we will take into account all your desires, as well as, do our utmost to achieve the best results. Jappware can provide you with the e-commerce software development, which includes the UI/UX design elaboration, cloud technologies implementation, efficiently used artificial intelligence and data analytics, along with the PCI security standards compliance. Likewise, in order to optimize processes comprehensively, we can integrate your custom e-commerce solutions with the CRM systems, CMS, ERP software, payment gateways or any other kind of a third-party software.
                        </p>
                        <Link
                          to='/case-study-ecommerce'
                          className='read-more-link text-decoration-none mt-4 d-block'
                        >
                          Read the case study
                          <i className='far fa-arrow-right ms-2'></i>
                        </Link>
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <img
                        src='assets/img/screen/widget-11.png'
                        alt='feature tab'
                        className='img-fluid mt-4 mt-lg-0 mt-xl-0'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeWorkWithTabOne;
