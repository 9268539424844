import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import IntelligentAutomationHeader from "../../components/intelligent-automation/IntelligentAutomationHeader";
import IntelligentAutomationDetail from "../../components/intelligent-automation/IntelligentAutomationDetail";
import InteligentAutomationHeroNine from "../../components/intelligent-automation/InteligentAutomationHeroNine";
import InteligentAutomationTechTab from "../../components/intelligent-automation/InteligentAutomationTechTab";
import InteligentAutomationPromo from "../../components/intelligent-automation/InteligentAutomationPromo";
import InteligentAutomationWorkProcess from "../../components/intelligent-automation/InteligentAutomationWorkProcess";

const IntelligentAutomation = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <InteligentAutomationHeroNine />
      <InteligentAutomationTechTab />
      <InteligentAutomationWorkProcess />
      <InteligentAutomationPromo />

      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default IntelligentAutomation;
