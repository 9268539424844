import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import CaseStudiesHeader from "../../components/case-studies/CaseStudiesHeader";
import CaseStudiesDetail from "../../components/case-studies/CaseStudiesDetail";

const CaseStudies = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <CaseStudiesHeader />
      <CaseStudiesDetail />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default CaseStudies;
