import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import CtaTwo from '../../components/cta/CtaTwo';
import RelatedJobs from "../../components/career/RelatedJobs";
import JobProductOwnerDetails from "../../components/career/JobProductOwnerDetails";
import JobProductOwnerHeader from "../../components/career/JobProductOwnerHeader";

const CareerProductOwner = () => {
  return (
    <Layout>
        <PageMeta />
        <Navbar navDark />
        <JobProductOwnerHeader />
        <JobProductOwnerDetails />
        <RelatedJobs />
        <CtaTwo />
        <FooterOne footerLight />
    </Layout>
  );
};

export default CareerProductOwner;
