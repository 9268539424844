import React from 'react';
import SectionTitle from '../common/SectionTitle';

const InteligentAutomationWorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Process'
                title='Our methodology'
                description='We’ll work closely with you to implement intelligent automation in a way that makes sense for your business'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                <img
                  src='assets/img/office-img-1.jpg'
                  alt='work process'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>01. Automation strategy</span>
                    <h3 className='h5 mb-2'>We work with you to understand your objectives and how automation can drive value for your business.</h3>
                    {/*<p>*/}
                    {/*  Progressively foster enterprise-wide systems whereas*/}
                    {/*  equity invested web-readiness harness installed base*/}
                    {/*  bandwidth.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>02. Value case for transformation</span>
                    <h3 className='h5 mb-2'>Based on your objectives, we develop a business case for change and detail out the appropriate programme to deliver your ambitions.</h3>
                    {/*<p>*/}
                    {/*  Dramatically administrate progressive metrics without*/}
                    {/*  error-free globally simplify standardized alignments*/}
                    {/*  plagiarize distributed.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-layer-group fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>03. Programme delivery</span>
                    <h3 className='h5 mb-2'>Following on from the value case, we work in collaboration with you to deliver the most appropriate automation programme.</h3>
                    {/*<p>*/}
                    {/*  Interactively whiteboard transparent testing procedures*/}
                    {/*  before bricks-and-clicks initiatives administrate*/}
                    {/*  competencies.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>04. Scaling</span>
                    <h3 className='h5 mb-2'>We help you scale and integrate automation into your ways of working (wider transformation programmes).</h3>
                    {/*<p>*/}
                    {/*  Dramatically plagiarize distributed progressive metrics*/}
                    {/*  without error-free globally simplify standardized*/}
                    {/*  alignments.*/}
                    {/*</p>*/}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InteligentAutomationWorkProcess;
