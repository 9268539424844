import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import ProductEngineeringContent from "../../components/product-engineering/ProductEngineeringContent";
import ProductEngineeringFeatureOne from "../../components/product-engineering/ProductEngineeringFeatureOne";
import ProductEngineeringWorkProcess from "../../components/product-engineering/ProductEngineeringWorkProcess";
import ProductEngineeringCtaFour from "../../components/product-engineering/ProductEngineeringCtaFour";
import ProductEngineeringCustomerLogo from "../../components/product-engineering/ProductEngineeringCustomerLogo";
import ProductEngineeringTechTab from "../../components/product-engineering/ProductEngineeringTechTab";

const ProductEngineering = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <ProductEngineeringContent />
      <ProductEngineeringTechTab />
      <ProductEngineeringWorkProcess />
      <ProductEngineeringCtaFour />
      <ProductEngineeringCustomerLogo />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default ProductEngineering;
