import React from 'react';
import { Link } from 'react-router-dom';

const Team = () => {
  return (
    <>
      <section id='our-team' className='team-section pt-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='section-heading text-center'>
                <h5 className='h6 text-primary'>Our Team</h5>
                <h2>The People Behind Hireya</h2>
                <p>
                  Meet our incredible team{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                      src='assets/img/team/team-1.png'
                      alt='team'
                      className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                      {/*<Link to='https://www.linkedin.com/in/nikolay-zahariev-85a47922b/'>*/}
                      {/*  <i className='fab fa-linkedin-in'></i>*/}
                      {/*</Link>*/}
                      <a href="https://www.linkedin.com/in/nikolay-zahariev-85a47922b/" target="_blank" rel="noreferrer">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-twitter'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-github'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-facebook-f'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Nikolay Zahariev</h5>
                  <p className='text-muted small mb-0'>Head of IT Sales Operations</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                      src='assets/img/team/team-3.png'
                      alt='team'
                      className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                      {/*<Link to='https://www.linkedin.com/in/krasimir-terziev-39b968246/'>*/}
                      {/*  <i className='fab fa-linkedin-in'></i>*/}
                      {/*</Link>*/}
                      <a href='https://www.linkedin.com/in/krasimir-terziev-39b968246/' target='_blank'
                         rel='noreferrer'>
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-twitter'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-github'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-facebook-f'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Krasimir Terziev</h5>
                  <p className='text-muted small mb-0'>Business Development Manager</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                      src='assets/img/team/team-2.png'
                      alt='team'
                      className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                      {/*<Link to='https://www.linkedin.com/in/plamen-terziev-793001106/'>*/}
                      {/*  <i className='fab fa-linkedin-in'></i>*/}
                      {/*</Link>*/}
                      <a href="https://www.linkedin.com/in/plamen-terziev-793001106/" target="_blank"
                         rel="noreferrer">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-twitter'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-github'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-facebook-f'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Plamen Terziev</h5>
                  <p className='text-muted small mb-0'>Senior Java Developer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/team-4.png'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                      {/*<Link to='https://www.linkedin.com/in/ivan-sarafov-9834b5298/'>*/}
                      {/*  <i className='fab fa-linkedin-in'></i>*/}
                      {/*</Link>*/}
                      <a href="https://www.linkedin.com/in/ivan-sarafov-9834b5298/" target="_blank"
                         rel="noreferrer">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-twitter'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-github'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to='#'>*/}
                    {/*    <i className='fab fa-facebook-f'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Ivan Sarafov</h5>
                  <p className='text-muted small mb-0'>Sales Representative</p>
                </div>
              </div>
            </div>

            {/*<div className='col-lg-3 col-md-6'>*/}
            {/*  <div className='team-single-wrap mb-5'>*/}
            {/*    <div className='team-img rounded-custom'>*/}
            {/*      <img*/}
            {/*        src='assets/img/team/team-5.jpg'*/}
            {/*        alt='team'*/}
            {/*        className='img-fluid position-relative'*/}
            {/*      />*/}
            {/*      <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-linkedin-in'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-twitter'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-github'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-facebook-f'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className='team-info mt-4 text-center'>*/}
            {/*      <h5 className='h6 mb-1'>John Sullivan</h5>*/}
            {/*      <p className='text-muted small mb-0'>Front End Developer</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='col-lg-3 col-md-6'>*/}
            {/*  <div className='team-single-wrap mb-5'>*/}
            {/*    <div className='team-img rounded-custom'>*/}
            {/*      <img*/}
            {/*        src='assets/img/team/team-6.jpg'*/}
            {/*        alt='team'*/}
            {/*        className='img-fluid position-relative'*/}
            {/*      />*/}
            {/*      <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-linkedin-in'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-twitter'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-github'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-facebook-f'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className='team-info mt-4 text-center'>*/}
            {/*      <h5 className='h6 mb-1'>John Sullivan</h5>*/}
            {/*      <p className='text-muted small mb-0'>Front End Developer</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='col-lg-3 col-md-6'>*/}
            {/*  <div className='team-single-wrap mb-5'>*/}
            {/*    <div className='team-img rounded-custom'>*/}
            {/*      <img*/}
            {/*        src='assets/img/team/team-7.jpg'*/}
            {/*        alt='team'*/}
            {/*        className='img-fluid position-relative'*/}
            {/*      />*/}
            {/*      <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-linkedin-in'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-twitter'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-github'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-facebook-f'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className='team-info mt-4 text-center'>*/}
            {/*      <h5 className='h6 mb-1'>John Sullivan</h5>*/}
            {/*      <p className='text-muted small mb-0'>Front End Developer</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='col-lg-3 col-md-6'>*/}
            {/*  <div className='team-single-wrap mb-5'>*/}
            {/*    <div className='team-img rounded-custom'>*/}
            {/*      <img*/}
            {/*        src='assets/img/team/team-8.jpg'*/}
            {/*        alt='team'*/}
            {/*        className='img-fluid position-relative'*/}
            {/*      />*/}
            {/*      <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-linkedin-in'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-twitter'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-github'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*        <li className='list-inline-item'>*/}
            {/*          <Link to='#'>*/}
            {/*            <i className='fab fa-facebook-f'></i>*/}
            {/*          </Link>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className='team-info mt-4 text-center'>*/}
            {/*      <h5 className='h6 mb-1'>John Sullivan</h5>*/}
            {/*      <p className='text-muted small mb-0'>Front End Developer</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
