import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const CaseStudyRetailAndConsumerServicesDetail = () => {
  return (
    <>
      <section className='feature-tab-section ptb-60 bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tab-content' id='nav-tabContent'>
                <div className='row justify-content-center align-items-center justify-content-around'>
                  <div className='col-lg-10'>
                    <div className='feature-tab-info pb-4'>
                      <h3>CLIENT</h3>
                      <p>
                        An online grocer providing an innovative alternative to traditional grocery shopping. As part of
                        such approach, the client invented a smart platform which is a full cycle solution for operating
                        online retail businesses from the box retailer.
                      </p>
                      <p>
                        Hireya has developed a full-scale custom marketplace platform. The solution supports a great
                        volume of traffic with a value-driven commerce model with an enhanced search engine. This provides
                        a personalized shopping approach.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>CHALLENGE</h3>
                      <p>
                        The primary objective was to create a comprehensive web platform providing maximum transparency and
                        an efficient E-commerce infrastructure. The vendors would be provided with the ability to sign-up,
                        create a seller storefront, view their dashboard, add different types of products, manage orders,
                        create an invoice, and arrange shipment.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>SOLUTION HIGHLIGHTS</h3>
                      <ul className='content-list list-unstyled'>
                        <li>
                          A highly engaging platform with focus on end users
                        </li>
                        <li>
                          Machine learning algorithms for optimal delivery routes and personalized CX
                        </li>
                        <li>
                          A comprehensive backend system including advanced search functionalities
                        </li>
                        <li>
                          Cloud solutions for data management
                        </li>
                        <li>
                          Powerful data analytics
                        </li>
                        <li>
                          Payment processing system
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*<div className='col-lg-5'>*/}
                  {/*  <img*/}
                  {/*      src='assets/img/screen/widget-12.png'*/}
                  {/*      alt='feature tab'*/}
                  {/*      className='img-fluid mt-4 mt-lg-0 mt-xl-0'*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyRetailAndConsumerServicesDetail;
