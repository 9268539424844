import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyStatementDetail = () => {
  return (
      <>
        <section className='support-content ptb-120'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 col-md-8'>
                <div className='tab-content' id='v-pills-supportContent'>
                  <div
                      className='tab-pane fade show active'
                      id='support-tab-1'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Privacy Policy</h2>
                      <ul className='support-article-list list-unstyled mt-4'>
                        <li className='py-4'>
                          <h3 className='h5 support-article-title'>
                            1. Introduction
                          </h3>
                        </li>
                        <li className='py-1'>
                          <p>
                            The privacy and confidentiality of your data is a top priority for Hireya. The Privacy Policy will inform you
                            how we protect your data. Read this policy carefully. The Policy applies to your use of Hireya website at
                            https://www.hireya.org/ and all other web resources developed by Hireya and published on the Internet.
                            This Policy does not apply to any other websites used but not controlled by Hireya. By using our Website
                            and reading this Policy, you confirm that you understand and accept that we may use your personal data. If you
                            do not agree to your personal data processing, please do not use the Website. We may need to change this Policy
                            from time to time, so you should refer back to this document regularly. Your use of the Website after changes
                            have been made shall be deemed to be your acceptance of any changes.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            2. What information we may collect and how we may use it
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We only gather information about you that is needed to allow you to use the Website, and only to the extent specifically
                            provided in this Policy. By using the Website, you consent to the collection, use, disclosure, and other forms of
                            processing of your information in the manner, provided in this Policy.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            2.1. What information we may obtain from you
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Examples of personal data that we may collect from you are the following: full name, a record of communication, language skills,
                            e-mail, phone number, and location. <br/>
                            When you contact us via the request form, we keep a record of your communication to help us solve issues you might face.
                            We may use your e-mail to inform you of our services, such as upcoming changes or enhancements.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            2.2. Information we may obtain from your use of our Website
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We may collect the following information about you:
                          </p>
                        </li>
                        <ul className='content-list list-unstyled'>
                          <li>
                            information about your device(s) such as the hardware model, OS version, mobile network information;
                          </li>
                          <li>
                            login information such as details regarding how you use our Website, frequency, and duration of your visits,
                            search information, internet protocol address, cookies. The “cookies” may be used by us to manage the Website
                            and provide functionality and customized messages to you, but we do not disclose this information to third parties,
                            except as required by law. Hireya also uses session cookies. These are not permanent cookies which are removed when
                            your browser is closed or your computer is off. For more information about cookies and opt-out options, please visit the
                            Cookies Info page.
                          </li>
                        </ul>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            2.3. Processing personal data of children
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            This site is not meant for children under 16 and does not collect any personal data of children under 16.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            3. Purposes of personal data processing
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We use all your personal data only for the following purposes:
                          </p>
                        </li>
                        <ul className='content-list list-unstyled'>
                          <li>
                            operate, provide, enhance, and maintain our Website and services;
                          </li>
                          <li>
                            prevent the abusive and fraudulent use of our Website and services;
                          </li>
                          <li>
                            respond to your inquiries and for other customer service purposes;
                          </li>
                          <li>
                            monitor aggregate Website usage metrics such as total number of visitors and pages viewed;
                          </li>
                          <li>
                            remember your information so that you will not have to re-enter it the next time you visit the Website;
                          </li>
                          <li>
                            remember your information so that you will not have to re-enter it the next time you visit the Website;
                          </li>
                          <li>
                            for other administrative and internal business purposes.
                          </li>
                        </ul>
                        <li className='py-1'>
                          <p>
                            In case the purposes are changed, we will ask for your consent for the data processing.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            4. Security of your personal data
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hireya uses a variety of security measures to keep your sensitive data protected from unauthorised access and disclosure.
                            For more details, please contact Hireya Privacy Team via info@hireya.org.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5. Principles of personal data processing
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We process your personal data based on the following principles of fairness and lawfulness:
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.1. Fairness and lawfulness
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We process your personal data only after obtaining your consent for the processing and for a determined specific
                            purpose unless other specified by law. Hireya has developed clear procedures regarding the processing of personal
                            data based on lawfulness.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.2. Transparency
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We process your personal data in a fair and transparent manner, in respect of your given rights.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.3. Purpose limitation
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We ensure that your personal data is only processed within the purposes outlined in the consent form and only by
                            those Hireya needs to be involved in the processing operations to fulfill these purposes. Subsequent changes to
                            the purpose are only possible to a limited extent, and require additional consent from you. <br/>
                            We will ask for your consent before using information for a purpose other than that defined in this Policy.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.4. Data minimization
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We gather and process only your personal data if it is actually needed in order to achieve the processing purposes.
                            Hireya also does not retain your personal data longer than it is required to achieve the relevant purposes.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.5. Accuracy
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Personal data must be accurate and, if required, kept up-to-date. We take every reasonable step to ensure that your data,
                            when inaccurate or incomplete, is either deleted or corrected. We ensure that inaccurate or misleading data will be edited
                            as soon as possible.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.6. Storage limitation
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hireya is limiting the storage scope and time to a level appropriate to fulfill the purposes of the data processing in
                            line with your consent. Therefore, we have specified limited periods for personal data storage.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.7. Integrity and confidentiality
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We acknowledge personal data to be sensitive. Our priority is to secure the confidentiality and integrity of your processed
                            personal data. Hireya secures your personal data with suitable organizational and technical measures to prevent
                            unauthorized access, illegal processing or distribution, as well as accidental loss, modification, or destruction.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            5.8. Accountability
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We are responsible for the compliance with the aforesaid six data protection principles.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            6. How we process your personal data
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We may process your data by the following ways: collecting, recording, organizing, storing, using, structuring,
                            adapting or altering, retrieving, consulting, disclosing by transmission, disseminating or otherwise making available,
                            aligning or combining, restricting, and erasing or destructing. We may transfer your personal data to our subsidiaries.
                            In this case, they are obliged to provide you with an adequate level of personal data protection. We will not transfer
                            personal data of the European Economic Area residents out of the European Economic Area without reasonably ensuring
                            that your rights and freedoms as a data subject are protected and that you are informed of such transfer. We do not
                            sell or pass your personal data to a third party for the purposes of direct marketing.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            7. Where we store your personal data
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            All your personal data, collected by us, is stored within the information technology systems, owned and operated by Hireya,
                            and in the cloud systems. Once we receive your information, we will use all reasonable procedures and security measures
                            to avoid unauthorized access, loss, disclosure or amendment of the received data.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            8. Disclosure of your information
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We do not disclose any information about you to any third party without your written consent. However, we will have
                            to release specific information about you, if necessary, in relation to the services, provided to you, or to comply
                            with any valid legal process such as a search warrant, subpoena, statute or court order. We will also have to release
                            specific information in special cases, such as if there is an attempted breach of the security of the Website, or to
                            report to the legal enforcement officials any activities that we reasonably believe to be unlawful. While Hireya
                            maintains the secure Website, you should know that computer use may be monitored by third parties, such as network
                            administrators, employers, internet providers, operation system and Internet browser vendors, and thus we cannot guarantee
                            the security of information you publish online. You disclose all information online at your own risk. Please remember
                            that the Website may be hosted outside your home country, and your e-mail communications to us may be directed to a
                            server outside your home country. Communications over the Internet may not be secure.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            9. Your privacy rights
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We provide you with data protection rights specified by the European Union laws in the General Data Protection
                            Regulation document, and the relevant national laws. You have, for example, the following rights:
                          </p>
                        </li>
                        <ul className='content-list list-unstyled'>
                          <li>
                            the right to obtain any information about the processed sensitive data within one month;
                          </li>
                          <li>
                            the right to access your personal data;
                          </li>
                          <li>
                            the right to correct or delete your personal data;
                          </li>
                          <li>
                            the right to be aware of any correction or deletion of your personal data, restriction of processing, and recipients
                            of personal data;
                          </li>
                          <li>
                            the right to reject the processing of your personal data;
                          </li>
                          <li>
                            the right of data portability;
                          </li>
                          <li>
                            the right not to be subject to a decision, based solely on the automated processing;
                          </li>
                          <li>
                            the right to lodge a complaint with a data protection authority.
                          </li>
                        </ul>
                        <li className='py-1'>
                          <p>
                            You may ask for these rights by sending a request to info@hireya.org
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default PrivacyStatementDetail;
