import React from 'react';
import { Link } from 'react-router-dom';

const ReviewOne = () => {
  return (
      <>
        <section className='customer-review pb-120 bg-dark'>
          <div className='container'>
            <div className='row'>
              <div className='section-heading text-center' data-aos='fade-up'>
                <h2 className='fw-medium h4'>
                  We Specialize in
                </h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-4'>
                <div
                    className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                    data-aos='fade-up'
                    data-aos-delay='50'
                >
                  {/*<img*/}
                  {/*  src='assets/img/fb-logo-w.svg'*/}
                  {/*  width='130'*/}
                  {/*  alt='facebook'*/}
                  {/*  className='img-fluid m-auto'*/}
                  {/*/>*/}
                  <div className='section-heading text-center' data-aos='fade-up'>
                    <h2 className='fw-medium h3'>
                      FinTech
                    </h2>
                  </div>

                  <div className='review-info-content mt-2'>
                    <p className='mb-4'>
                      Whether it's consumer banking, commercial lending, or trading, startups are taking over every industry imaginable. Having worked on multiple financial services over the years, we are happy to bring our expertise to your team.
                    </p>
                  </div>
                  <Link
                      to='/fintech'
                      className='
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  '
                  >
                    Learn More <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4'>
                <div
                    className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                    data-aos='fade-up'
                    data-aos-delay='100'
                >
                  {/*<img*/}
                  {/*  src='assets/img/g-logo-w.svg'*/}
                  {/*  width='130'*/}
                  {/*  alt='google'*/}
                  {/*  className='img-fluid m-auto'*/}
                  {/*/>*/}
                  <div className='section-heading text-center' data-aos='fade-up'>
                    <h2 className='fw-medium h3'>
                      Retail &amp; Consumer
                    </h2>
                  </div>

                  <div className='review-info-content mt-2'>
                    <p className='mb-4'>
                      We build software solutions for all aspects of the ecommerce experience. From developing custom API integrations, POS Retail synchronization, payment merchant integration, or building entire omnichannel experiences, we have a proven track record of getting incredible results for clients.
                    </p>
                  </div>
                  <Link
                      to='/retail-consumer'
                      className='
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  '
                  >
                    Learn More <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4'>
                <div
                    className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                    data-aos='fade-up'
                    data-aos-delay='150'
                >
                  {/*<img*/}
                  {/*  src='assets/img/li-logo-w.svg'*/}
                  {/*  width='130'*/}
                  {/*  alt='linkedIn'*/}
                  {/*  className='img-fluid m-auto'*/}
                  {/*/>*/}
                  <div className='section-heading text-center' data-aos='fade-up'>
                    <h2 className='fw-medium h3'>
                      eCommerce
                    </h2>
                  </div>

                  <div className='review-info-content mt-2'>
                    <p className='mb-4'>
                      Building cutting-edge eCommerce solutions tailored to your business needs. We implement ecommerce aggregator websites to let you host and manage diverse brands conveniently while enabling them to promote their services and goods.
                    </p>
                  </div>
                  <Link
                      to='/ecommerce'
                      className='
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  '
                  >
                    Learn More <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default ReviewOne;
