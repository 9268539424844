import React from 'react'

export default function WorkProcessFive() {
    return (
        <section
            className="ptb-60"
            style={{ background: "url('assets/img/payment-step.jpg')no-repeat center center / cover" }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center mb-5">
                            <h2 className="text-white mb-3">Our Expertise</h2>
                            {/*<p className="text-off-white">*/}
                            {/*    Access your account via your mobile phone. View balance, transfer*/}
                            {/*    funds, view transactions wherever you are Login with fingerprint*/}
                            {/*    or Face ID..*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                <div className="row mb--150">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-payment-step p-4 mb-4 mb-lg-0">
                            <img src="assets/img/pi-1.png" alt="icon" />
                            <h6 className="mt-3">Core Banking Software</h6>
                            <p className="mb-0">We work on complex systems with customer-focused core banking features, using Oracle FLEXCUBE
                                and other leading technologies. Our team helps financial institutions streamline deposit, credit, and loan
                                processing activities, enabling better customer experience with their account transactions, using domain
                                expertise and a modern tech stack.
                                <br/>
                                <br/>
                                <br/>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-payment-step p-4 mb-4 mb-lg-0">
                            <img src="assets/img/pi-2.png" alt="icon" />
                            <h6 className="mt-3">Cash & Treasury Management</h6>
                            <p className="mb-0">Hireya works on consolidated cash and treasury management solutions, helping our clients
                                create fully-fledged operational management systems based on modern technology. Our experts can also update
                                legacy treasury systems, add new functionality, and migrate users to the next-gen software, fix legacy code
                                and optimize system usability.
                                <br/>
                                <br/>
                                <br/>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-payment-step p-4 mb-4 mb-lg-0">
                            <img src="assets/img/pi-3.png" alt="icon" />
                            <h6 className="mt-3">Omnichannel Banking</h6>
                            <p className="mb-0">Hireya helps banks tap into growing customer demands they want to satisfy switching from
                                one device to another. With real-time data synchronization, our financial products and services enable a
                                seamless experience across all platforms, putting customers at the center of banking operations. We empower
                                financial institutions to stay relevant and coherent with smartphones, apps, and websites, providing easy-to-use
                                and intuitive services.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-payment-step p-4 mb-4 mb-lg-0">
                            <img src="assets/img/pi-4.png" alt="icon" />
                            <h6 className="mt-3">Onboarding & KYC</h6>
                            <p className="mb-0">Hireya’s IT services for banking help financial institutions with complex client verification
                                processes by introducing robust risk management solutions based on modern technology. We offer FinServ
                                compliance tools to guarantee smooth onboarding and further customer engagement. Our experts take care
                                of KYC/AML procedures by automating sophisticated banking workflows.
                                <br/>
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
