import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import CtaTwo from '../../components/cta/CtaTwo';
import RelatedJobs from "../../components/career/RelatedJobs";
import JobUiUxProductDesignerHeader from "../../components/career/JobUiUxProductDesignerHeader";
import JobUiUxProductDesignerDetails from "../../components/career/JobUiUxProductDesignerDetails";

const CareerUiUxProductDesigner = () => {
  return (
    <Layout>
        <PageMeta />
        <Navbar navDark />
        <JobUiUxProductDesignerHeader />
        <JobUiUxProductDesignerDetails />
        <RelatedJobs />
        <CtaTwo />
        <FooterOne footerLight />
    </Layout>
  );
};

export default CareerUiUxProductDesigner;
