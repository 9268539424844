import React from 'react';
import { Link } from 'react-router-dom';

const AccessibilityStatementDetail = () => {
  return (
      <>
        <section className='support-content ptb-120'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 col-md-8'>
                <div className='tab-content' id='v-pills-supportContent'>
                  <div
                      className='tab-pane fade show active'
                      id='support-tab-1'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Accessibility Statement</h2>
                      <ul className='support-article-list list-unstyled mt-4'>
                        <li className='py-1'>
                          <p>
                            Hireya, Inc. is committed to making its website accessible to individuals with disabilities. With that intent, Hireya is in the process
                            of making sure its website and content pages meet the accessibility standards established by the World Wide Web Consortium (W3C),
                            particularly the Web Content Accessibility Guidelines (WCAG) 2.0 Level A/AA, and the additional elements proposed by W3C in its WCAG 2.1.
                            During this process, we recommend using the most current version of your browser and any assistive technologies to gain the best user experience.
                          </p>
                          <p>
                            If you have experienced any issues with accessing an element of this site or you need assistance with the content, please let us know by
                            sending an email to info@hireya.org. If you are seeking employment with Hireya, we will make every reasonable effort to provide an accommodation
                            that allows you equal access to employment regardless of a disability.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AccessibilityStatementDetail;
