import React from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../../components/common/HeroTitle';
import SectionTitle from "../../components/common/SectionTitle";

const HomePage = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden'
        style={{
          background:
            "url('assets/img/shape/color-particles-2.svg') no-repeat center top",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <HeroTitle
                  title='  Build Tech Teams'
                  desc='     We build core teams for growing technology companies and startups. With our support, your remote team will feel like an integral part of your in-house staff.'
                />
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <Link to='/career' className='btn btn-primary me-3'>
                    View Available Positions
                  </Link>
                  <Link to='/contact-us' className='btn btn-outline-primary'>
                    Talk to Sales
                  </Link>
                </div>
              </div>
            </div>
            {/*<div className='col-lg-9'>*/}
            {/*  <div*/}
            {/*    className='position-relative'*/}
            {/*    data-aos='fade-up'*/}
            {/*    data-aos-delay='200'*/}
            {/*  >*/}
            {/*    <ul*/}
            {/*      className='*/}
            {/*  position-absolute*/}
            {/*  animate-element*/}
            {/*  parallax-element*/}
            {/*  widget-img-wrap*/}
            {/*  z-2*/}
            {/*'*/}
            {/*    >*/}
            {/*      <li className='layer' data-depth='0.04'>*/}
            {/*        <img*/}
            {/*          src='assets/img/screen/widget-3.png'*/}
            {/*          alt='widget-img'*/}
            {/*          className='*/}
            {/*      img-fluid*/}
            {/*      widget-img-1*/}
            {/*      position-absolute*/}
            {/*      shadow-lg*/}
            {/*      rounded-custom*/}
            {/*    '*/}
            {/*        />*/}
            {/*      </li>*/}
            {/*      <li className='layer' data-depth='0.02'>*/}
            {/*        <img*/}
            {/*          src='assets/img/screen/widget-4.png'*/}
            {/*          alt='widget-img'*/}
            {/*          className='*/}
            {/*      img-fluid*/}
            {/*      widget-img-3*/}
            {/*      position-absolute*/}
            {/*      shadow-lg*/}
            {/*      rounded-custom*/}
            {/*    '*/}
            {/*        />*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*    <img*/}
            {/*      src='assets/img/dashboard-img.png'*/}
            {/*      alt=''*/}
            {/*      className='img-fluid position-relative rounded-custom mt-5'*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

              <div className='row'>
                <div className='col-lg-4 col-md-6'>
                  <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                    <div className='promo-icon mb-32'>
                      <i className='fas fa-code text-primary fa-3x'></i>
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Custom Software Development</h3>
                      <p className='mb-0 text-start'>
                        Standard software does not meet your requirements? We offer you a complete range of custom software development services - from system architecture and UX/UI design to front-end and back-end testing and post-delivery maintenance.
                      </p>
                    </div>
                    {/* <!--pattern start--> */}
                    <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                      <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                    </div>
                    {/* <!--pattern end--> */}
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                    <div className='promo-icon mb-32'>
                      <i className='fas fa-user-friends text-success fa-3x'></i>
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Custom Application Development</h3>
                      <p className='mb-0 text-start'>
                        You need an app, but you don’t have the time or the skills to develop it yourself? Our industry-specific technology experience enables us to deliver highly scalable and interoperable web, mobile, desktop, and hybrid applications.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                    <div className='promo-icon mb-32'>
                      <i className='fas fa-bezier-curve text-danger fa-3x'></i>
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>IT Technology Consulting</h3>
                      <p className='mb-0 text-start'>
                        Have a specific business need or a technical challenge? Rely on our specialized experience and knowledge. Our consultants will develop a comprehensive IT strategy for your company's full-scale digital and technology to meet your business goals.
                      </p>
                    </div>
                    {/* <!--pattern start--> */}
                    <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                      <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                    </div>
                    {/* <!--pattern end--> */}
                  </div>
                </div>
              </div>


          </div>
        </div>
        <div
          className='
      bg-dark
      position-absolute
      bottom-0
      h-25
      bottom-0
      left-0
      right-0
      z--1
      py-5
    '
        ></div>
      </section>
    </>
  );
};

export default HomePage;
