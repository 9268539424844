import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PrivacyStatementHeader from "../../components/privacy-statement/PrivacyStatementHeader";
import PrivacyStatementDetail from "../../components/privacy-statement/PrivacyStatementDetail";

const PrivacyStatement = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <PrivacyStatementHeader />
      <PrivacyStatementDetail />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default PrivacyStatement;
