import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import DataAnalyticsHeader from "../../components/data-analytics/DataAnalyticsHeader";
import DataAnalyticsDetail from "../../components/data-analytics/DataAnalyticsDetail";
import DataAnalyticsFeatureImgContentTwo from "../../components/data-analytics/DataAnalyticsFeatureImgContentTwo";
import DataAnalyticsFeatureImgContentSix from "../../components/data-analytics/DataAnalyticsFeatureImgContentSix";
import DataAnalyticsCtaThree from "../../components/data-analytics/DataAnalyticsCtaThree";
import DataAnalyticsFeatureFour from "../../components/data-analytics/DataAnalyticsFeatureFour";

const DataAnalytics = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <DataAnalyticsFeatureImgContentTwo />
      <DataAnalyticsFeatureImgContentSix />
      <DataAnalyticsCtaThree />
      <DataAnalyticsFeatureFour />

      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default DataAnalytics;
