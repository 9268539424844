import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import CtaTwo from '../../components/cta/CtaTwo';
import OpenJobs from '../../components/career/OpenJobs';
import CareerPromo from '../../components/career/CareerPromo';
import PageHeader from '../../components/common/PageHeader';
import JobHeader from "../../components/career/JobHeader";
import JobDetails from "../../components/career/JobDetails";
import RelatedJobs from "../../components/career/RelatedJobs";
import JobSeniorJavaDeveloperHeader from "../../components/career/JobSeniorJavaDeveloperHeader";
import JobSeniorJavaDeveloperDetails from "../../components/career/JobSeniorJavaDeveloperDetails";

const CareerSeniorJavaDeveloper = () => {
  return (
    <Layout>
        <PageMeta />
        <Navbar navDark />
        <JobSeniorJavaDeveloperHeader />
        <JobSeniorJavaDeveloperDetails />
        <RelatedJobs />
        <CtaTwo />
        <FooterOne footerLight />
    </Layout>
  );
};

export default CareerSeniorJavaDeveloper;
