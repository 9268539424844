import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import EcommerceHeader from "../../components/ecommerce/EcommerceHeader";
import EcommerceDetail from "../../components/ecommerce/EcommerceDetail";
import EcommerceContent from "../../components/ecommerce/EcommerceContent";
import HeroTen from "../../themes/index10/HeroTen";
import CustomerLogoSlider from "../../components/customer/CustomerLogoSlider";
import CyberAbout from "../../components/about/CyberAbout";
import CyberService from "../../components/services/CyberService";
import CyberCta from "../../components/cta/CyberCta";
import CyberVideoPromo from "../../components/promo/CyberVideoPromo";
import PriceFour from "../../components/prices/PriceFour";
import CyberStore from "../../components/others/CyberStore";
import TestimonialFour from "../../components/testimonial/TestimonialFour";
import CyberFaq from "../../components/faqs/CyberFaq";
import CyberBlog from "../../components/blog/CyberBlog";
import FooterTwo from "../../layout/Footer/FooterTwo";
import FaqThree from "../../components/faqs/FaqThree";
import EcommerceAdditionalServices from "../../components/ecommerce/EcommerceAdditionalServices";

// https://radixweb.com/services/ecommerce-website-development
const Ecommerce = () => {
  return (
    <Layout>
      <Navbar navDark />
      <HeroTen />
      {/*<CustomerLogoSlider />*/}
      {/*<CyberAbout />*/}
      <CyberService />
      <CyberCta />
      {/*<CyberVideoPromo />*/}
      {/*<PriceFour />*/}
      {/*<CyberStore />*/}
      {/*<TestimonialFour />*/}
      <EcommerceAdditionalServices />
      {/*<CyberBlog />*/}

      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default Ecommerce;
