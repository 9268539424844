import React from 'react';
import SectionTitle from '../common/SectionTitle';

const ProductEngineeringWorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='METHODOLOGY'
                title='Service launch process'
                description='Our approach, demonstrated across multiple sectors with a particular focus on consumer, BFSI, and iGaming, will take you through the following steps, ensuring your requirements are met at all stages of our partnership.'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                <img
                  src='assets/img/office-img-1.jpg'
                  alt='work process'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>01. Discussion</span>
                    <h3 className='h5 mb-2'>Our industry experts will work with you to identify the most appropriate services to meet your needs.</h3>
                    {/*<p>*/}
                    {/*  Progressively foster enterprise-wide systems whereas*/}
                    {/*  equity invested web-readiness harness installed base*/}
                    {/*  bandwidth.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>02. Discovery</span>
                    <h3 className='h5 mb-2'>A discovery phase sets our mutual partnership on the right track, aligning with your product strategy, and how we can support you in delivering it.</h3>
                    {/*<p>*/}
                    {/*  Dramatically administrate progressive metrics without*/}
                    {/*  error-free globally simplify standardized alignments*/}
                    {/*  plagiarize distributed.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-layer-group fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>03. Mobilisation</span>
                    <h3 className='h5 mb-2'>We deploy our right-shore model to establish the right team set up for you, utilising our global talent pool.</h3>
                    {/*<p>*/}
                    {/*  Interactively whiteboard transparent testing procedures*/}
                    {/*  before bricks-and-clicks initiatives administrate*/}
                    {/*  competencies.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>04. Continuous discovery and continuous delivery</span>
                    <h3 className='h5 mb-2'>Data-driven delivery practices help us increase the speed of development and speed to market, helping you redefine your industry.</h3>
                    {/*<p>*/}
                    {/*  Dramatically plagiarize distributed progressive metrics*/}
                    {/*  without error-free globally simplify standardized*/}
                    {/*  alignments.*/}
                    {/*</p>*/}
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>05. Ongoing partnership</span>
                    <h3 className='h5 mb-2'>Throughout our partnership we will act as your trusted technology partner, bringing both industry expertise as well as leading engineering practices.</h3>
                    {/*<p>*/}
                    {/*  Dramatically plagiarize distributed progressive metrics*/}
                    {/*  without error-free globally simplify standardized*/}
                    {/*  alignments.*/}
                    {/*</p>*/}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductEngineeringWorkProcess;
