import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditionsDetail = () => {
  return (
      <>
        <section className='support-content ptb-120'>
          <div className='container'>
            <div className='row justify-content-center'>
              {/*<div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>*/}
              {/*  <div className='support-article-sidebar sticky-sidebar'>*/}
              {/*    <div*/}
              {/*        className='nav flex-column nav-pills support-article-tab bg-light rounded-custom p-5'*/}
              {/*        id='v-pills-support'*/}
              {/*        role='tablist'*/}
              {/*        aria-orientation='vertical'*/}
              {/*    >*/}
              {/*      <button*/}
              {/*          className='nav-link active'*/}
              {/*          data-bs-target='#support-tab-1'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='true'*/}
              {/*      >*/}
              {/*        Terms of use*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-2'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Code of conduct*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-3'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Compliance Policy*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-4'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Dignity and Respect*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-5'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Anti-Human Trafficking Statement*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-6'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Whistleblower Policy*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-7'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Privacy Notice*/}
              {/*      </button>*/}
              {/*      <button*/}
              {/*          className='nav-link'*/}
              {/*          data-bs-target='#support-tab-8'*/}
              {/*          data-bs-toggle='pill'*/}
              {/*          type='button'*/}
              {/*          role='tab'*/}
              {/*          aria-selected='false'*/}
              {/*      >*/}
              {/*        Cookie Policy*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*    /!*<div className='bg-light p-5 mt-4 rounded-custom quick-support'>*!/*/}
              {/*    /!*  <Link*!/*/}
              {/*    /!*      to='contact-us.html'*!/*/}
              {/*    /!*      className='text-decoration-none text-muted d-flex align-items-center py-2'*!/*/}
              {/*    /!*  >*!/*/}
              {/*    /!*    <div className='quick-support-icon rounded-circle bg-success-soft me-3'>*!/*/}
              {/*    /!*      <i className='far fa-ballot-check text-success'></i>*!/*/}
              {/*    /!*    </div>*!/*/}
              {/*    /!*    <div className='contact-option-text'>*!/*/}
              {/*    /!*      Quick Support Form*!/*/}
              {/*    /!*    </div>*!/*/}
              {/*    /!*  </Link>*!/*/}
              {/*    /!*  <Link*!/*/}
              {/*    /!*      to='mailto:info@test.com'*!/*/}
              {/*    /!*      className='text-decoration-none text-muted d-flex align-items-center py-2'*!/*/}
              {/*    /!*  >*!/*/}
              {/*    /!*    <div className='quick-support-icon rounded-circle bg-primary-soft me-3'>*!/*/}
              {/*    /!*      <i className='far fa-envelope text-primary'></i>*!/*/}
              {/*    /!*    </div>*!/*/}
              {/*    /!*    <div className='contact-option-text'>*!/*/}
              {/*    /!*      info@test.com*!/*/}
              {/*    /!*    </div>*!/*/}
              {/*    /!*  </Link>*!/*/}
              {/*    /!*  <Link*!/*/}
              {/*    /!*      to='#'*!/*/}
              {/*    /!*      target='_blank'*!/*/}
              {/*    /!*      className='text-decoration-none text-muted d-flex align-items-center py-2'*!/*/}
              {/*    /!*  >*!/*/}
              {/*    /!*    <div className='quick-support-icon rounded-circle bg-danger-soft me-3'>*!/*/}
              {/*    /!*      <i className='far fa-comment-alt-lines text-danger'></i>*!/*/}
              {/*    /!*    </div>*!/*/}
              {/*    /!*    <div className='contact-option-text'>Live Support Chat</div>*!/*/}
              {/*    /!*  </Link>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className='col-lg-8 col-md-8'>
                <div className='tab-content' id='v-pills-supportContent'>
                  <div
                      className='tab-pane fade show active'
                      id='support-tab-1'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Terms of use</h2>
                      <ul className='support-article-list list-unstyled mt-4'>
                        <li className='py-4'>
                          <h3 className='h5 support-article-title'>
                            1. HIREYA’S TERMS &amp; CONDITIONS OF USE
                          </h3>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.1. Acceptance of Terms
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            PLEASE READ THE FOLLOWING TERMS AND CONDITIONS RELATING TO YOUR USE OF THIS WEBSITE CAREFULLY.
                            BY USING THIS WEBSITE, YOU ARE DEEMED TO HAVE AGREED TO THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY,
                            WHICH IS INCORPORATED HEREIN BY REFERENCE. WE RESERVE THE RIGHT TO CHANGE THESE TERMS AND CONDITIONS AND
                            THE PRIVACY POLICY AT ANY TIME. YOU SHOULD CHECK THESE TERMS AND CONDITIONS PERIODICALLY FOR CHANGES.
                            BY USING THIS WEBSITE AFTER WE POST ANY CHANGES TO THESE TERMS AND CONDITIONS, YOU AGREE TO ACCEPT THOSE CHANGES,
                            WHETHER OR NOT YOU HAVE REVIEWED THEM. IF AT ANY TIME YOU CHOOSE NOT TO ACCEPT THESE TERMS AND CONDITIONS
                            PLEASE DO NOT USE THIS WEBSITE.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.2. Description and use of this Website
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hireya requires that all visitors to this World Wide Website owned, operated, licensed, and controlled by
                            Hireya (the “Website”) adhere to the following Terms And Conditions of Use. Hireya may change, suspend or
                            discontinue any aspect of the Website at any time, including the availability of any feature, database or content.
                            Hireya may also impose limits on certain features and services and/or restrict your access to parts or
                            all of the Website without notice or liability of any kind. To the extent that you and Hireya have entered
                            into a client’s service/license agreement (“Client Agreement”) for any portion of the Website, the terms and
                            conditions of the Client Agreement will control in the event that of any provisions that may conflict with
                            these Terms and Conditions of Use.
                          </p>
                          <p>
                            This Website provides authorized users with access to a collection of resources, including access to certain
                            service deliverables, articles, and insights for general informational purposes. The Content (as such term is
                            defined below) is to be used solely as a research tool and not as specific guides for action. YOU UNDERSTAND
                            AND AGREE THAT THIS WEBSITE AND THE CONTENT IS PROVIDED “AS-IS” AND YOU ASSUME FULL RISK FOR ANY AND ALL USE
                            OF THIS WEBSITE AND FOR ANALYSIS OF THE CONTENT. You may not use this Website or the Content for any illegal
                            purpose or in any manner inconsistent with these Terms and Conditions of Use. We make every effort to ensure
                            that the Content on this Website is accurate and up to date, however accuracy cannot be guaranteed.
                          </p>
                          <p>
                            You are responsible for obtaining access to the Service and that access may involve third party fees (such as
                            Internet service provider or airtime charges). In addition, you must provide and are responsible for all
                            equipment necessary to access the Website. No installation, implementation, customization, consultation,
                            support or similar services are included within the scope of these Terms and Conditions of Use.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.3. Password
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            To the extent that your use of this Website is in connection with services received through a Client Agreement,
                            we will request that you select a Password and Screen Name in connection with the operation of this Website.
                            You alone are responsible for maintaining the confidentiality of your Password and Screen Name. You are
                            responsible for all uses of our services with your Password and Screen Name, with or without your consent,
                            by yourself and others.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.4. Forums/Blogs
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            You may access certain “forums” or “blogs” through the Website. You may not post on any forum/blog any
                            material that is libelous, defamatory, obscene, abusive, invades a person’s privacy, violates any intellectual
                            property rights, or that would otherwise violate any law. You acknowledge that you are responsible for whatever
                            material you submit, and you, not Hireya, have full responsibility for the message, including its legality,
                            reliability, appropriateness, originality, and copyright. You may not post material that solicits funds,
                            or that advertises or solicits goods or services. You may not post material known to be false. You may not
                            post or transmit any information, software or other material that contains a virus or other harmful component.
                            We also reserve the right to remove any such violative material. All remarks, suggestions, ideas, graphics
                            or other information communicated through the Website will become, once posted, the property of Hireya (“Posting”).
                            Hireya will not be required to treat any Posting as confidential. Hireya will be entitled to use the Postings
                            for any commercial or other purpose whatsoever, without compensation to you or any other person. You agree to
                            indemnify, defend and hold Hireya harmless from any liability arising due to the use or distribution of
                            your Postings. You further grant Hireya the right to use your name in connection with the reproduction or
                            distribution of your Postings.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.5. Modifications to Website
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            We reserve the right at any time to modify or discontinue, temporarily or permanently, this Website (or any
                            part thereof) with or without notice. You agree that we shall not be liable to you or to any third party
                            for any modification, suspension or discontinuance of this Website.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.6. Copyright/Restrictions on use of Content
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            The content, including but not limited to the written materials, images, audio, video and all other materials
                            and information on this Website (“Content”) is the intellectual property of Hireya or republished by Hireya
                            under limited exceptions to the copyright laws. All rights of Hireya or other copyright holders are reserved.
                            Content from this Website may only be used, copied, and/or reproduced for internal business informational
                            purposes. In no event may it be used for the commercialization of any product, service or entity, or released
                            to the general public. You agree that if you download any Content that you will assure that the Content is used
                            only as permitted by these Terms and Conditions of Use. You also agree that you will not change any Content or
                            create your own derivative work from any Content. Any other use of or link to the Content on this Website,
                            including reproduction for purposes other than those noted above, without the prior written permission of Hireya
                            is strictly prohibited. You may seek our permission by writing to Hireya, Attention: Data Controller,
                            Østerbrogade 125, 3rd floor DK 2100 Copenhagen Ø Cvr: 26768624. If permission is granted by us (or by other
                            entities with an interest in the relevant intellectual property), you may not change or delete any author
                            attribution, trademark, legend or copyright notice. In the event that you are given authority to use any Content,
                            your use of any Content should be attributed to Hireya or the author if specified
                          </p>
                          <p>
                            Where copyright for any Content is not owned by Hireya, the source of this Content is provided (“Third Party Content”).
                            Any copying, republication or redistribution of Third Party Content whatsoever is expressly prohibited.
                            Thus, should you wish to use any Third Party Content you are responsible for seeking appropriate approvals from
                            the copyright owner.
                          </p>
                          <p>
                            If you use Content from this Website in violation of these Terms and Conditions of Use you may be in violation
                            of your Client Agreement and may be subject to termination of your Client Agreement and damages for the copyright
                            violation under federal statutes.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.7. Trademarks/Logos
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            This Website contains many valuable trademarks, names, titles, logos and other proprietary materials owned and
                            registered by Hireya and its affiliates (“Trademarks”). Nothing contained on this Website should be construed
                            as granting any license or right to use any Trademark displayed on this Website. All other trademarks appearing
                            on the Website are trademarks of their respective owners and nothing contained on this Website should be construed
                            as granting any license to use the trademark owned by any other third party. Our reference to any third party
                            trademarks does not imply or indicate any approval or endorsement by their owners, or Hireya’s approval or endorsement
                            of the owners or their products or services.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.8. Linked Sites
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            This Website may include links to other sites which are not maintained by Hireya. Hireya is not responsible for
                            the content of those sites and makes no representations whatsoever concerning the content or accuracy of, opinions
                            expressed in or other links provided by, such other sites. The inclusion of any link to such sites does not imply
                            endorsement by Hireya of the sites or any products or services referred to therein. Hireya may terminate a link
                            at any time. The terms of use and privacy policies applicable to such sites may be different from those applicable
                            to the Website. If you decide to access any third party site linked to the Website, you do so entirely at your own
                            risk and Hireya shall have no liability for any loss or damage arising from your use of any such site. Any owner
                            of a site which may be referred to or to which a link is provided in this Website, may request that the link be
                            removed, by promptly notifying us in writing by fax or regular mail (not by email) at the following address:
                            Data Controller, Østerbrogade 125, 3rd floor DK 2100 Copenhagen Ø Cvr: 26768624. Please include the following
                            information in your Notice: (i) your full name, address, telephone number and email address; (ii) full details of
                            the location on this Website of the material or information in question (including the URL of the link shown on
                            the Website where such material or information may be found); and (iii) full details of why you want the link removed.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.9. Notification of Alleged Infringement of Copyright or Other Intellectual Property Rights
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            In order to promptly address claims of alleged infringement of intellectual property rights, we have established
                            the following procedures. If you are the owner of copyright or other Intellectual Property Rights, or have been
                            authorized to act on behalf of the owner of such rights, and you believe your rights have been or are being infringed,
                            please notify us immediately. Notice should be in writing and should be sent by fax or regular mail (not by email)
                            to the following address: Data Controller, Østerbrogade 125, 3rd floor DK 2100 Copenhagen Ø Cvr: 26768624 Notices
                            should include the information set forth below. Please note that you will be liable for damages (including costs
                            and attorneys’ fees) if you materially misrepresent that an activity is infringing your copyrights or other Intellectual
                            Property Rights. Accordingly, if you are not sure whether certain material of yours is protected by copyright laws,
                            we suggest that you first contact an attorney. To expedite our ability to process your request, please use the
                            following format (including section numbers): (i) Identify in sufficient detail the copyrighted work that you believe
                            has been infringed. For example, “The copyrighted work at issue is the text that appears on http://www.legal.com/legal_page.html”;
                            (ii) Identify the material that you claim is infringing the copyrighted work listed in item #1 above. Identify
                            each page that allegedly contains infringing material by providing its URL; (iii) Provide information reasonably
                            sufficient to permit us to contact you (email address is preferred); (iv) Include the following statement: “I have
                            a good faith belief that use of the copyrighted materials as described above is not authorized by the copyright owner,
                            its agent, or the law.”; (v) Include the following statement: “I swear, under penalty of perjury, that the information
                            in the notification is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an
                            exclusive right that is allegedly infringed.” ; (vi) Sign the paper; and (vii) Send the written communication to our
                            designated contact, Data Controller. Failure to include all of the above information may result in a delay of the
                            processing the notification.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.10. Notification of Alleged Unlawful Activities
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            If you believe in good faith that any unlawful activity is taking place on or through this Website or that any unlawful
                            information has been submitted or uploaded on or to this Website, please promptly report the same to us by sending
                            written notice by fax or regular mail (not by email) to the following address: Data Controller, BOSTON, MA
                            2 Drydock Avenue, Suite 412 W Boston, MA 02210 USA. Please include the following information in your Notice of Unlawful
                            Activity: (i) your full name, address, telephone number and email address; (ii) full details of the location on
                            this Website of the material or information in question (including the URL of the link shown on the Website where
                            such material or information may be found); and (iii) full details of the unlawful nature of the activity or
                            material or information in question.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.11. What we will do when we receive a Notice of Infringement or Notice of Unlawful Activity
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            If we receive full and proper notification of an alleged infringement of Intellectual Property Rights or of any
                            unlawful activity occurring on or through this Website or of any unlawful information being submitted or uploaded
                            on or to this Website, we will take such action which we believe to be appropriate in the circumstances. Without
                            limiting the foregoing, we will make an attempt to secure the voluntary take down of the Content which is the
                            subject of any Notice of Infringement or Notice of Unlawful Activity. In any event, we reserve the right, in our
                            sole discretion and without notice to any User, to: (i) delete, move, edit and/or disable access to any Content
                            which is the subject of any Notice of Infringement or Notice of Unlawful Activity; or (ii) terminate access of
                            any User whose Content is the subject of any Notice of Infringement or Notice of Unlawful Activity.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.12. License
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Use of portions of this Website may be subject to the terms of a Client Agreement. Subject to the terms of the Client
                            Agreement, we grant to you a limited, nonexclusive, nontransferable, nonsublicensable license to use this Website,
                            solely for your internal business purposes. The Client Agreement may limit the number of users who are allowed access to
                            this Website. You are required to take reasonable measures to protect access information and to assure that you do not
                            permit unauthorized use of or access to this Website. We may audit your use of this Website to assure compliance with
                            access restrictions and will pursue all available remedies for a breach of these access rules. You may not sell, rent,
                            lease, lend, or transfer any services provided on this Website and you may not assign or sublicense your license to
                            this Website. To the extent that you are not subject to a Client Agreement, we grant to you a limited, nonexclusive,
                            nontransferable, nonsublicensable license to use those non-password protected portions of this Website, solely for
                            your personal or business use. The licenses set forth herein may be terminated in the event you breach the terms of
                            these Terms and Conditions of Use. RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY HIREYA.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.13. Termination
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            You understand that we may in our sole discretion terminate your ability to use this Website if you violate these
                            Terms and Conditions of Use. You may terminate these Terms and Conditions of Use by destroying all materials
                            obtained from this Website and all related documentation and all copies and installations. Hireya may terminate
                            these Terms and Conditions of Use immediately without notice if, in its sole judgment, you breach any of these
                            Terms and Conditions of Use. Upon termination, you must destroy all materials obtained from this site and all
                            related documentation and all copies and installations. You may not access this site after termination of these
                            Terms and Conditions of Use without the written approval of Hireya, provided, however, that Hireya shall retain
                            all rights, including all copyright rights and the right to use Postings as provided herein, and the limitations
                            on use and treatment of the Contents shall remain in full force.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.14. Disclaimer
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
                          </p>
                          <p>
                            1.YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                          </p>
                          <p>
                            2. WE MAKE NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED,
                            TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
                            OR RELIABLE, (iv) THE QUALITY OF THE CONTENT OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, (v)
                            ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED, (vi) OR THAT THIS WEB WEBSITE, ITS CONTENT, AND THE SERVERS ON WHICH
                            THE WEB WEBSITE AND CONTENT ARE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                          </p>
                          <p>
                            3. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION
                            AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                          </p>
                          <p>
                            4. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM THE SERVICE SHALL
                            CREATE ANY WARRANTY.
                          </p>
                          <p>
                            5. THIS WEBSITE MAY CONTAIN VARIOUS COMBINATIONS OF TEXT, IMAGES, AUDIOVISUAL PRODUCTIONS, OPINIONS, STATEMENTS,
                            FACTS, ARTICLES, MARKET DATA, STOCK QUOTES OR OTHER INFORMATION CREATED BY US OR BY THIRD-PARTIES. DUE TO THE
                            NUMBER OF SOURCES FROM WHICH CONTENT IN THIS WEBSITE IS OBTAINED, AND THE INHERENT HAZARDS OF ELECTRONIC DISTRIBUTION,
                            THERE MAY BE DELAYS, OMISSIONS OR INACCURACIES IN SUCH CONTENT. ACCORDINGLY, SUCH CONTENT, INCLUDING THE MARKET DATA,
                            IS FOR YOUR REFERENCE ONLY AND SHOULD NOT BE RELIED UPON BY YOU FOR ANY PURPOSE.
                          </p>
                          <p>
                            6. INFORMATION CREATED BY THIRD PARTIES THAT YOU MAY ACCESS ON THE WEBSITE OR THROUGH LINKS IS NOT ADOPTED OR
                            ENDORSED BY HIREYA AND REMAINS THE RESPONSIBILITY OF SUCH THIRD PARTIES.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.15. Limitation of Liability
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT HIREYA SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                            CONSEQUENTIAL OR EXEMPLARY DAMAGES, (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) WHATSOEVER.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.16. Non-Waiver
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Our failure to exercise or enforce any right or provision of hereunder shall not constitute a waiver of such right
                            or provision. If any provision hereunder is found by a court of competent jurisdiction to be invalid, the parties
                            nevertheless agree that the court should attempt to give effect to the parties’ intentions as reflected in the provision,
                            and the other provisions hereof shall remain in full force and effect. You agree that regardless of any statute or
                            law to the contrary, any claim or cause of action arising out of or related to use of the Website or these Terms and
                            Conditions of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.17. Indemnity
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            You agree to indemnify, and hold Hireya and its affiliates and each of their directors, officer, employees and agents
                            (“Hireya Indemnities”) harmless from and against any and all claims, actions or demands brought against Hireya
                            Indemnities arising out of or relating to your use of the Website or your breach of these Terms and Conditions of Use.
                            The indemnification set forth in this Paragraph shall include all expenses and costs (including reasonable attorney fees)
                            resulting from or arising out of the above claims.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.18. Agreement
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            By using the Website, you are deemed to agree to these Terms and Conditions of Use.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className='tab-pane fade'
                      id='support-tab-2'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Code of conduct</h2>
                      <ul className='support-article-list list-unstyled mt-4'>
                        <li className='py-4'>
                          <h3 className='h5 support-article-title'>
                            1. Introduction
                          </h3>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.1. Message from CEO
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Integrity is one of the core values in which we, Hireya, operate. The Code of Conduct is company-wide and describes how we put Hireya values in the way we work every day. It also explains our commitments and, more importantly, expectations towards everyone. Commitments I make to you and, likewise, commitments you make to me and everyone we deal with both internally and externally.
                          </p>
                          <p>
                            We run our business ethically and responsibly. We are committed to complying with the highest standards of integrity. We commit to prevent corruption, fraud and anti-competitive ways of working. We are respectful and follow the laws of the land where we operate.
                          </p>
                          <p>
                            A document can never cover one hundred percent of every situation you may face on a day-to-day basis. The Code of Conduct is your map. It illustrates the values and provides the tools that will help you, that will guide you in making the right decisions consistently and ethically. Also, it is important to raise your hand, speak out if you become aware or suspect a violation of the Code. We introduced Hireya “Speak Up” to help you with this notification so you may do it with one hundred percent confidentiality and privacy.
                          </p>
                          <p>
                            Our Code of Conduct is important, critical and imperative. We are all responsible for the reputation of Hireya and it is an important commitment for us to make and to honor.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.2. Purpose of this Code
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            As a multi-national group of companies with an identifiable brand the actions of one Hireyer 1 has the potential to impact upon the entire group. Furthermore, with the diverse range of jurisdictions in which Hireya operates there is a wide range of laws, rules, regulations, customs and approaches to conducting business. The continued success of Hireya depends on the actions of every Hireyaer being transparent, lawful and ethical. As such, the primary function of this Code is to provide a unified set of principles and behaviours which are designed to act as a guide to help you make the right decisions for yourself and for Hireya.
                          </p>
                          <p>
                            At the same time, the Code of Conduct cannot describe every law, policy or process that may apply to us or every situation that we may face within our everyday role. As a general rule, we are responsible for understanding and complying with the laws, regulations and policies that relate to our business activities.
                          </p>
                          <p>
                            Detailed guidance on how to deal with important ethical and compliance issues will be described in Hireya’s corporate policies and other relevant documents. These will be available on the Hireya's portal. However, being aware, understanding and following the principles described in this Code will help to protect the reputation of Hireya and Hireyans.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.3. Scope
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            This Code applies to and is mandatory to be followed by all Hireyans. Each of us, wherever we work, must behave in accordance with these standards when dealing with fellow Hirenyans, clients, suppliers, stakeholders, governmental
                            authorities and competitors. We also should require our suppliers or vendors to adhere to this Code or adopt similar ethical standards.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.4. Responsibilities
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hirenyans <br/>
                            The primary responsibility for maintaining an environment of ethical behaviour rests with Hirenyans through a demonstrated commitment to compliance with the Code of Conduct and with legal and regulatory requirements relevant to Hireya’s business. To carry out this responsibility, Hirenyans will:
                          </p>
                          <ul className='list-group-numbered'>
                            <li>Read, understand, and abide by the Code of Conduct.
                            </li>
                            <li>Exercise appropriate behaviour and maintain the highest standards of ethical conduct when representing Hireya. This includes when travelling on the company business and attending functions hosted by Hireya or third parties (e.g., conferences, trade shows, events). Seek advice from a supervisor2 if uncertain about the meaning or application of the Code of Conduct or when in doubt about the best course of action in a particular situation.
                            </li>
                            <li>Learn and contribute to a workplace environment that is conducive to and encourages compliance with the Code of Conduct and with laws and regulations.
                            </li>
                            <li>Maintain sensitivity to alleged, actual, or suspected illegal, unethical, or improper conduct by a supplier, client, consultant, or other person or organization with whom Hireya has a relationship, and to report such conduct to the Compliance Director.
                            </li>
                          </ul>
                        </li>
                        <li className='py-1 mt-4'>
                          <p>
                            Leadership Team <br/>
                            In addition to their professional responsibilities in Hireya, Senior Management (Leadership team & Invited Members3) must maintain a workplace environment that stresses commitment to compliance with the Code of Conduct and with laws and regulations. Hireya’s Leadership Team will:
                          </p>
                          <ul className='list-group-numbered'>
                            <li>
                              Exhibit the highest standards of ethical conduct at all times and avoid the perception of unethical behaviour.
                            </li>
                            <li>
                              Ensure that Hirenyans understand their duty to report actual or suspected Code of Conduct violations and that there are procedures and mechanisms available to facilitate reporting.
                            </li>
                            <li>
                              Ensure that Hirenyans receive appropriate training in the meaning and application of Hireya’s compliance documents.
                            </li>
                            <li>
                              Ensure that all policies and references are in place and in the laws and regulations related to Hireya.
                            </li>
                            <li>
                              Maintain a workplace environment that prevents reprisals against a Hirenyans who in good faith reports actual or suspected Code of Conduct violations.
                            </li>
                          </ul>
                        </li>
                        <li className='py-1 mt-4'>
                          <p>
                            Board of Directors<br/>
                            The Board of Directors4 is accountable for ensuring overall compliance with the Code of Conduct and the legal and regulatory requirements relevant to the Hireya’s business. The Board has the overall responsibility and authority to:
                          </p>
                          <ul className='list-group-numbered'>
                            <li>
                              Approve and/or make modifications to the Code of Conduct as needed.
                            </li>
                            <li>
                              Review current and proposed corporate policies, processes, and procedures for consistency with the Code of Conduct.
                            </li>
                            <li>
                              Establish and maintain the means, methods, and procedures for investigating violations of the Code of Conduct.
                            </li>
                            <li>
                              Monitor disciplinary measures taken for violations of the Code of Conduct.
                            </li>
                            <li>
                              Provide training and educational programs to enhance Hirenyans awareness of and compliance with the Code of Conduct.
                            </li>
                          </ul>
                        </li>
                        <li className='py-1 mt-4'>
                          <h4 className='h5 support-article-title'>
                            1.5. How to raise an issue or concern?
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            The Code cannot cover every situation that we may face. We may find ourselves faced with a dilemma that we are not sure how to resolve. If in doubt, ask yourself these questions:
                          </p>
                          <ul className='list-group-numbered'>
                            <li>
                              Does it comply with the Hireya’s Code of Conduct?
                            </li>
                            <li>
                              Does it comply with policy, regulation and law?
                            </li>
                            <li>
                              Would I be setting a good example?
                            </li>
                            <li>
                                Would I be comfortable explaining what I did to my colleagues, family and friends without shame or embarrassment?
                            </li>
                            <li>
                              Would I or Hireya be comfortable if the action was written about in a newspaper?
                            </li>
                            <li>
                              Have I consulted others who have knowledge of the topic and sought advice to help me make an informed decision?
                            </li>
                          </ul>
                          <p className='mt-4'>
                            If the answer is ‘No’ to any of these questions, or if you are not sure, stop and seek further advice.
                            If you become aware or suspect a violation of this Code, we expect you to report promptly to your Line Manager / Project Manager, HR Business Partner / People Partner or Compliance Department. If you are uncomfortable making such a notification, you may do so anonymously using Hireya SpeakUp.
                          </p>
                          <p>
                            There are a number of ways that you can seek advice and support, including through any of the following:
                          </p>
                          <ul className='list-group-numbered'>
                            <li>
                              Discussing with your Project Manager, People Partner or anyone in management;
                            </li>
                            <li>
                              Discussing with the Compliance Department; and
                            </li>
                            <li>
                              Writing to Hireya following the Whistleblower Policy
                            </li>
                          </ul>
                          <p className='mt-4'>
                            Never hesitate to ask questions, raise concerns, or seek the guidance you need. Hireya will not tolerate any discrimination against anyone who has reported a concern in good faith.
                          </p>
                        </li>
                        <li className='py-1'>
                          <h4 className='h5 support-article-title'>
                            1.6. Breach of this Code
                          </h4>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hireya will investigate any report of a violation with the principles of the Code of Conduct. You must cooperate fully with any investigation, but should not investigate independently as alleged violations may involve complex legal issues, and you may risk compromising the integrity of a formal investigation.
                          </p>
                          <p>
                            Conduct that violates the law and/or company policies is ground for disciplinary or remedial action. In addition, failure to report a known violation of law or company policy by someone else may result in disciplinary action for Hirenyans and/or termination of employment/your relationship with Hireya. The disciplinary action taken will be decided on a case by case basis. The action will be conducted in accordance with Hireya’s HR procedures and corresponding legal requirements. Where laws have been violated, we will cooperate fully with the appropriate authorities.
                          </p>
                        </li>
                        <li className='py-4'>
                          <h3 className='h5 support-article-title'>
                            2. Compliance with laws and regulations
                          </h3>
                        </li>
                        <li className='py-1'>
                          <p>
                            Hireya is a global group of companies, and our business is subject to the laws of many different countries. Each day we interact with a variety of individuals and groups including our clients, competitors, co-workers, suppliers, and sometimes government officials. We are committed to interacting with all in a respectful, ethical manner and in compliance with legal requirements. We would rather miss out on a business opportunity than compromise our integrity.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*<div*/}
                  {/*    className='tab-pane fade show active'*/}
                  {/*    id='support-tab-3'*/}
                  {/*    role='tabpanel'*/}
                  {/*>*/}
                  {/*  <div className='support-article-wrap'>*/}
                  {/*    <h2>Compliance Policy*/}
                  {/*    </h2>*/}
                  {/*    <ul className='support-article-list list-unstyled mt-4'>*/}
                  {/*      <li className='py-4'>*/}
                  {/*        <h3 className='h5 support-article-title'>*/}
                  {/*          1. Introduction*/}
                  {/*        </h3>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h4 className='h5 support-article-title'>*/}
                  {/*          1.1. Background*/}
                  {/*        </h4>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          At Hireya we are committed to doing business in accordance with our core principles – transparency and integrity, which in particular means avoiding bribery and corruption of all kinds and in wider sense this relates to not facilitating or engaging in any Financial Crime. This includes bribery and corruption, money laundering and sanctions.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireya defines these areas of financial crime as:*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <ul className='list-group-numbered'>*/}
                  {/*          <li>*/}
                  {/*            Bribery and Corruption: The act of offering, promising or giving a financial or other advantage to another person with the intent to induce improper performance of a business or public function. Corruption is the abuse of entrusted power for private gain. It can be classified as grand, petty and political, depending on the amounts of money lost and the sector where it occurs.*/}
                  {/*          </li>*/}
                  {/*          <li>*/}
                  {/*            Money Laundering: Money Laundering involves taking criminal proceeds and disguising their illegal source in anticipation of ultimately using the criminal proceeds to perform legal and illegal activities.*/}
                  {/*          </li>*/}
                  {/*          <li>*/}
                  {/*            Sanctions: Sanctions are restrictive measures applied by a country, or group of countries, to enforce change. Sanctions can be split into two distinct areas, financial and trade sanctions. Financial sanctions are restrictions on providing economic resource to certain entities or individuals. Trade sanctions limit the ability to trade in particular items to certain territories, entities or individuals. Trade sanctions can also apply to any ancillary services related to the goods/services which are restricted.*/}
                  {/*          </li>*/}
                  {/*        </ul>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Detailed definitions can be found in Sections 4, 5 and 6 of this Policy.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h4 className='h5 support-article-title'>*/}
                  {/*          1.2. Purpose of this Policy*/}
                  {/*        </h4>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          The purpose of this Policy is to guide Hireyans and to ensure that Hireya’s core values of transparency and integrity are maintained at all levels of Hireya. This policy is also designed to manage Hireya’s compliance risk, and to protect Hireya, our shareholders, and customers.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Managing compliance risk in the context of this policy refers to the risks presented by national and international financial crime laws and regulations. It is essential that Hireya has in place processes, systems and controls to manage this compliance risk as without it Hireya’s integrity, profitability and future growth ambitions could be comprised. To manage this compliance risk Hireya will:*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <ul className='list-group-numbered'>*/}
                  {/*          <li>*/}
                  {/*            Ensure there is regular identification and assessment of compliance risk across Hireya;*/}
                  {/*          </li>*/}
                  {/*          <li>*/}
                  {/*            Monitor any legal and regulatory changes to assess their impact on Hireya;*/}
                  {/*          </li>*/}
                  {/*          <li>*/}
                  {/*            Put in place processes, systems and controls to support the identification and mitigation of compliance risk; and*/}
                  {/*          </li>*/}
                  {/*          <li>*/}
                  {/*            Provide Hireyans with support and guidance.*/}
                  {/*          </li>*/}
                  {/*        </ul>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          This Policy applies to all Hireyans, including officers and directors. In addition, Hireya will require third parties who represent it (such as agents, consultants, and contractors) to conduct themselves where applicable in a manner consistent with this Policy.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireya will seek to comply with all applicable laws, rules and regulations in the jurisdictions in which it operates including those relating to anti-bribery and corruption (ABC), anti-money laundering (AML) and sanctions. For the purpose of combatting financial crime the decision has been taken to use the regulations imposed by the European Union (EU), United Nations (UN) and United States (US) as a benchmark.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          This decision has been made as these regulations are directly applicable to certain Hireyans and they are also seen as being the highest global standards to combat financial crime. In each jurisdiction Hireya operates in there are laws and regulations relating to bribery and corruption, money laundering and sanctions. Where these conflict with the requirements outlined by the EU, UN and US Hireya will take a decision on the appropriate course of action on a case by case basis.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Core Anti- Bribery and Corruption regulations – U.S. Foreign Corrupt Practices Act (“FCPA”) and the U.K. Bribery Act prohibit bribery of foreign government officials (broadly defined later in this policy), and with regard to the FCPA in particular, mandate that companies establish and maintain accurate books and records and sufficient internal controls. The UKBA also prohibits private sector (commercial) bribery.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          A violation of financial crime regulations can lead to severe civil and criminal penalties and is cause for disciplinary action (up to and including termination of employment or contract with Hireya1); it is vital that every Hireyans2 not only understands and appreciates the importance of these policies and procedures, but also complies with them in daily assignments.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h4 className='h5 support-article-title'>*/}
                  {/*          1.3. Compliance risk*/}
                  {/*        </h4>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Compliance risk (also referred as Financial Crime risk) is an indispensable part of Hireya’s overall risk. It is a risk Hireya may suffer in failure to comply with applicable laws, rules and standards including FCPA, UKBA, Anti-Money Laundering regulations, sanctions and embargo programs, and the Code of Conduct applicable to Hireya’s activities.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Compliance risk is also an integrity risk, because Hireya’s reputation is closely connected with its adherence to principles of transparency, integrity and fair dealing.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Compliance risk must be identified, assessed, advised on, monitored and reported timely to protect Hireya from any damage or losses such as legal or regulatory enforcement or sanctions, material financial losses, reputational losses, financial crime and corruption (destroying shareholders’ value).*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h4 className='h5 support-article-title'>*/}
                  {/*          1.4. Compliance principles*/}
                  {/*        </h4>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans, at all levels, are required to strictly abide with the principles outlined below while performing their duties in the Hireya. Abiding by these principles will help to manage the risk that Hireya, and/or Hireyans, are found to breach local laws, international laws and Hireya’s own compliance controls.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.1. Compliance with Laws, Rules & Regulations*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans have to act according to Hireya’s written policies and procedures in order to ensure full compliance with laws, rules and standards helping to maintain Hireya’s reputation, and meet the expectations of its customers, the relevant regulatory bodies, the markets and society as a whole; treating customers fairly, and ensuring suitability of customer advice.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.2. Promote and Engage in Ethical Conduct*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans, at all levels, are required to carry out their duties acting professionally and honestly, in good faith and with integrity respecting Hireya’s Code of Conduct; perform duties as described by their contracts or job descriptions; avoid misuse of authorities; properly use accessible information; not engage in any unethical/ illegal activities that may damage Hireya’s reputation; act with full transparency and in a bona fide; and report any illegal activities/ unethical behaviour/ suspicion of any illegal act, as per applicable policies and procedures.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.3. Avoid any Conflict of Interest*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans should act in the best interests of Hireya without giving any preference to any third party on the basis of personal considerations ensuring their private matters do not interfere with the interests of Hireya.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.4. Protect Confidentiality*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans have to maintain the confidentiality of information which may be related to Hireya and its clients (and entrusted to them) except when disclosure is authorised or required by law; even when they leave Hireya.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.5. Protect Hireya’s Assets*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans should protect and use Hireya’s property and assets for legitimate business purposes.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.6. Act in the Customer’s Best Interest*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans should provide services to Hireya’s customers with care, honesty and fairness; they should strictly avoid manipulation, or any unfair dealings. Accordingly, Hireya will ensure careful review of customers’ complaints, process them in a timely manner and document replies.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <h5 className='h6 support-article-title'>*/}
                  {/*          1.4.7. Raise any issues or concerns*/}
                  {/*        </h5>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          Hireyans are encouraged to report any violation of Hireya’s policies, Code of Conduct, potential violations of applicable laws and regulations, as well as other types of misconduct. Hireya pledges to protect all Hireyans against any kind of harassment from any other employee for reporting a potential violation in good faith.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-4'>*/}
                  {/*        <h3 className='h5 support-article-title'>*/}
                  {/*          2. Governance*/}
                  {/*        </h3>*/}
                  {/*      </li>*/}
                  {/*      <li className='py-1'>*/}
                  {/*        <p>*/}
                  {/*          The approach of Hireya’s compliance governance is based on clear roles and responsibilities for both Operational management (the first line of defence) and Compliance (the second line of defence). This is supported by oversight and challenge from senior management in the form of the Risk Management Committee (RMC). The roles and responsibilities of these functions is detailed below.*/}
                  {/*        </p>*/}
                  {/*      </li>*/}
                  {/*    </ul>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div
                      className='tab-pane fade'
                      id='support-tab-4'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Dignity and Respect</h2>
                      <ul className='support-article-list list-unstyled'>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you have any local branches?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What do I need to create an account?
                            </h3>
                            <p>
                              {' '}
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence. Credibly
                              evisculate mission-critical ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you offer refunds for the subscriptions?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical with high
                              standards in e-markets. Intrinsicly evisculate
                              e-business best practices productivate standardized
                              convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Intrinsicly evisculate
                              e-business best practices ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Are you open for new podcast guests?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              When is the upcoming annual event?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              e-business best practices productivate
                              standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices productivate
                              standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What’s inside the Facebook community?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets.
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className='tab-pane fade'
                      id='support-tab-5'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Anti-Slavery and Anti-Human Trafficking Statement</h2>
                      <ul className='support-article-list list-unstyled'>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you offer refunds for the subscriptions?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical with high
                              standards in e-markets. Intrinsicly evisculate
                              e-business best practices productivate standardized
                              convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Intrinsicly evisculate
                              e-business best practices ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you have any local branches?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What do I need to create an account?
                            </h3>
                            <p>
                              {' '}
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence. Credibly
                              evisculate mission-critical ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Are you open for new podcast guests?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              When is the upcoming annual event?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              e-business best practices productivate
                              standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices productivate
                              standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What’s inside the Facebook community?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets.
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className='tab-pane fade'
                      id='support-tab-6'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Whistleblower Policy</h2>
                      <ul className='support-article-list list-unstyled'>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you offer refunds for the subscriptions?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical with high
                              standards in e-markets. Intrinsicly evisculate
                              e-business best practices productivate standardized
                              convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Intrinsicly evisculate
                              e-business best practices ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you have any local branches?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              When is the upcoming annual event?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              e-business best practices productivate
                              standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What do I need to create an account?
                            </h3>
                            <p>
                              {' '}
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence. Credibly
                              evisculate mission-critical ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Are you open for new podcast guests?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices productivate
                              standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What’s inside the Facebook community?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets.
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className='tab-pane fade'
                      id='support-tab-7'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Privacy Notice</h2>
                      <ul className='support-article-list list-unstyled'>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you have any local branches?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What do I need to create an account?
                            </h3>
                            <p>
                              {' '}
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence. Credibly
                              evisculate mission-critical ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you offer refunds for the subscriptions?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical with high
                              standards in e-markets. Intrinsicly evisculate
                              e-business best practices productivate standardized
                              convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Intrinsicly evisculate
                              e-business best practices ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Are you open for new podcast guests?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              When is the upcoming annual event?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              e-business best practices productivate
                              standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices productivate
                              standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What’s inside the Facebook community?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets.
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className='tab-pane fade'
                      id='support-tab-8'
                      role='tabpanel'
                  >
                    <div className='support-article-wrap'>
                      <h2>Cookie Policy</h2>
                      <ul className='support-article-list list-unstyled'>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you have any local branches?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What do I need to create an account?
                            </h3>
                            <p>
                              {' '}
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence. Credibly
                              evisculate mission-critical ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Do you offer refunds for the subscriptions?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical with high
                              standards in e-markets. Intrinsicly evisculate
                              e-business best practices productivate standardized
                              convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Intrinsicly evisculate
                              e-business best practices ...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              Are you open for new podcast guests?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              When is the upcoming annual event?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              e-business best practices productivate
                              standardized...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What is the monthly cost of your app?
                            </h3>
                            <p>
                              Rapidiously incubate alternative infrastructures
                              with high standards in e-markets. Intrinsicly
                              evisculate e-business best practices productivate
                              standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                        <li className='py-4 border-top border-light'>
                          <Link
                              to='support-single.html'
                              className='text-decoration-none d-block text-muted'
                          >
                            <h3 className='h5 support-article-title'>
                              What’s inside the Facebook community?
                            </h3>
                            <p>
                              Credibly evisculate mission-critical products before
                              quality e-services. Rapidiously incubate alternative
                              infrastructures with high standards in e-markets.
                              Intrinsicly evisculate e-business best practices
                              productivate standardized convergence...
                            </p>
                            <span className='btn-link text-decoration-none read-more-link'>
                            Read More{' '}
                              <i className='far fa-arrow-right ms-2'></i>
                          </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default TermsAndConditionsDetail;
