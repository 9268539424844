import React from 'react';
import { Link } from 'react-router-dom';

export default function DigiWorkProcess() {
  return (
    <section className="digi-how-works bg-white ptb-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="digi-how-works-left">
              <div>
                <span className="span-arrow">Retail Software Development Across Various <br/> Business Areas</span>
                <img
                  src="assets/img/arro-right.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </div>
              <h2>
                Our Retail Software Development Process
                {/*<span className="text-orang">Social Media</span>*/}
              </h2>
              <p>
                Our comprehensive approach to software development combines our technical expertise, industry knowledge,
                and client-focused processes, resulting in high-quality, tailored solutions that drive business growth.
                So if you’re ready to transform your retail operations with an innovative, efficient software solution, contact us today.
              </p>
              <p>
                Custom software development has become a powerful way for companies to streamline operations, optimize efficiency,
                drive growth, and set themselves apart from their competitors. Retail software can include things like point-of-sale systems,
                inventory management tools, demand forecasting solutions, and custom offerings designed to meet the specific needs of the individual business.
              </p>
              <Link
                to="/contact-us"
                className="btn rounded-pill bg-orange mt-3"
              >
                Contact us
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="digi-how-works-right mt-5 mt-lg-0">
              <div className="dig-how-steps">
                <ul className="list-unstyled mb-0">
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/st-1.png"*/}
                    {/*    className="img-fluid mb-3 mb-md-0"*/}
                    {/*    alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 1</span>
                      <h6>Customer Request</h6>
                      <p className="m-0">
                        We gather all relevant details and requirements to fully understand the scope of the project.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*      src="assets/img/icons/st-1.png"*/}
                    {/*      className="img-fluid mb-3 mb-md-0"*/}
                    {/*      alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 2</span>
                      <h6>Detailed Specs & Cost Estimates</h6>
                      <p className="m-0">
                        We create detailed specs outlining the project scope, timeline, and cost estimates.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*      src="assets/img/icons/st-1.png"*/}
                    {/*      className="img-fluid mb-3 mb-md-0"*/}
                    {/*      alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 3</span>
                      <h6>Prototyping and UX/UI Design</h6>
                      <p className="m-0">
                        We create prototypes and design mockups to provide a visual representation of the final product.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*      src="assets/img/icons/st-1.png"*/}
                    {/*      className="img-fluid mb-3 mb-md-0"*/}
                    {/*      alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 4</span>
                      <h6>Frontend & Backend Development</h6>
                      <p className="m-0">
                        Our process involves both frontend and backend development.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*      src="assets/img/icons/st-1.png"*/}
                    {/*      className="img-fluid mb-3 mb-md-0"*/}
                    {/*      alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 5</span>
                      <h6>Testing</h6>
                      <p className="m-0">
                        We conduct thorough testing to ensure that the product is fully functional and meets all requirements.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/st-2.png"*/}
                    {/*    className="img-fluid mb-3 mb-md-0"*/}
                    {/*    alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 6</span>
                      <h6>Publishing & Execution</h6>
                      <p className="m-0">
                        Once the product has passed all testing and quality assurance checks, we deliver the final product to the customer.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/st-3.png"*/}
                    {/*    className="img-fluid mb-3 mb-md-0"*/}
                    {/*    alt="icons"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <span className="text-orange fw-bold">Step 7</span>
                      <h6>Support</h6>
                      <p className="m-0">
                        We also provide maintenance and support services to ensure that the product remains fully functional and up-to-date.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="shape">
                  <ul className="list-unstyled mb-0">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
