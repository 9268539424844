import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const EcommerceAdditionalServices = () => {
  return (
    <>
      <section className='faq-section ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle='On-Demand'
                title='Our On-Demand Ecommerce Development Services'
                description='Redefine your Ecommerce Development process with a set of high performing and value
                induced on-demand services to render momentum to your enterprise'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-copyright text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>
                    Selecting Platforms
                  </h5>
                  <p className='mb-0'>
                    We analyze your business processes and help you decide on the most suited platform for development. We craft bespoke features for your
                    online store according your business value to add ROI streams
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-credit-card text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>User Interface</h5>
                  <p className='mb-0'>
                    We conduct research to analyze the behavior of your user base. We create highly intuitive interfaces to improve engagement
                    with customers and easily navigable features to promote conversion
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-clipboard-list-check text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>Experience Tools</h5>
                  <p className='mb-0'>
                    Amplify user experience and ease their process with shopping experience tools like Add to cart, Wishlist, Flexible payment,
                    gateway options, easy checkouts and many others and build new ROI streams.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-microphone text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>Social Media Integration</h5>
                  <p className='mb-0'>
                    Connect with social media to amplify your digital presence. Leverage the power of sharing products on Facebook, Instagram, Twitter
                    and several other platforms
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='250'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-play-circle text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>Brand Positioning</h5>
                  <p className='mb-0'>
                    Our bespoke designs are built keeping in mind the essence of your brand, they speak of your business vision. Our designs
                    enable clear content positioning to promote your business
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-10'>
              <div
                className='faq-content-wrap d-flex mb-5'
                data-aos='fade-up'
                data-aos-delay='300'
              >
                <span className='faq-icon me-3'>
                  <i className='fal fa-code-branch text-primary'></i>
                </span>
                <div className='faq-info'>
                  <h5>Security Measures</h5>
                  <p className='mb-0'>
                    Hireya adheres strongly to all security compliances like SSL certificates, regular updates and bug free solutions.
                    Trust our experts to cross check all checkpoints to ensure no glitch
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EcommerceAdditionalServices;
