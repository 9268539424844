import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const CaseStudyEcommerceServicesDetail = () => {
  return (
    <>
      <section className='feature-tab-section ptb-60 bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tab-content' id='nav-tabContent'>
                <div className='row justify-content-center align-items-center justify-content-around'>
                  <div className='col-lg-10'>
                    <div className='feature-tab-info pb-4'>
                      <h3>CLIENT</h3>
                      <p>
                        A global media and technology company with headquarters in North America. As an association of
                        12 brands, it
                        provides all sorts of content, tools, products, and services for customers, planning to
                        celebrate a family milestone.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>CHALLENGE</h3>
                      <p>
                        The client was looking for a reliable vendor for deep re-platforming of their eCommerce product
                        to modernize the tools, improve flexibility and increase growth.
                      </p>
                      <p>
                        Primary challenge was to find a solution that will help to improve the inefficiency of
                        assortment management,
                        which grows by adding more and more product attributes. As well as to find a solution that will
                        allow to support
                        unique business processes and at the same time will leverage out-of-the-box capabilities of
                        eCommerce platforms
                        available on a market.
                      </p>
                      <p>
                        The other challenge was to improve process of assortment management, which was one of the
                        blockers for revenue growth.
                        Customer was keen on existing business processes automation.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>SOLUTION</h3>
                      <p>
                        Our client has a quite unique bespoke catalog, where they have a catalog of products and a lot
                        of designs to customize
                        this product. Hireya has started with the replacement of the existing platform with a
                        best-in-class headless eCommerce
                        solution, that will allow for the customization and extensions required to support unique
                        products.
                      </p>
                      <p>
                        Hireya analyzed BigCommerce and Commercetools as candidates for eCommerce platform core.
                        BigCommerce won the
                        competition because of more user-friendly admin panel and features, available through the
                        internal BC marketplace.
                        Complex order fulfillment process was implemented with a custom Zendesk application, so customer
                        support got a
                        single working environment for their daily routine tasks.
                      </p>
                      <p>
                        Hireya developed and introduced a unique catalog management approach, where assortment
                        management was implemented
                        in Contentful and everything related to the price management was handled by BigCommerce.
                      </p>
                    </div>
                    <div className='feature-tab-info'>
                      <h3>BUSINESS BENEFITS</h3>
                      <ul className='content-list list-unstyled'>
                        <li>
                          Time spent for adding new product was reduced from 14h to 1h
                        </li>
                        <li>
                          Customer support got a single app to manage orders
                        </li>
                        <li>
                          Automatic order fulfillment process for certain cases
                        </li>
                        <li>
                          Extensible catalog, with ability to add new product attributes in short term
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*<div className='col-lg-5'>*/}
                  {/*  <img*/}
                  {/*      src='assets/img/screen/widget-12.png'*/}
                  {/*      alt='feature tab'*/}
                  {/*      className='img-fluid mt-4 mt-lg-0 mt-xl-0'*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyEcommerceServicesDetail;
