import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import CookiePolicyHeader from "../../components/cookie-policy/CookiePolicyHeader";
import CookiePolicyDetail from "../../components/cookie-policy/CookiePolicyDetail";

const CookiePolicy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <CookiePolicyHeader />
      <CookiePolicyDetail />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default CookiePolicy;
