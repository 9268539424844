import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const CaseStudyFintechServicesDetail = () => {
  return (
    <>
      <section className='feature-tab-section ptb-60 bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tab-content' id='nav-tabContent'>
                <div className='row justify-content-center align-items-center justify-content-around'>
                  <div className='col-lg-10'>
                    <div className='feature-tab-info pb-4'>
                      <h3>CLIENT</h3>
                      <p>
                        The client is a group of companies offering technological solutions to B2B and B2C customers.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>CHALLENGE</h3>
                      <p>
                        The client’s business model involves acquiring software development companies and mapping out
                        business excellence strategy, thus improving their technological offering and applications. At
                        some point in the company’s development, the client realized that they needed to integrate with
                        third party payment providers and gateways in order to make payments through their systems and
                        applications. However, due to substantial fees incurred by payment providers, the client
                        realized that creating own custom payment gateway would be more effective and cost‐efficient.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>SOLUTION</h3>
                      <p>
                        The initial version of the payment gateway was written by the previous vendor. Hireya was
                        engaged because the client had not been satisfied with the speed of development and wanted more
                        integrations. Hireya took over the ownership of the previous system, streamlined its
                        functionality and added more features. The previous version of the payment gateway supported the
                        integration with the first version of 3D Secure and had limited post-terminal functionality.
                        Hireya’s team developed the payment gateway with a number of important integrations, including
                        3D Secure 2.0 and American Express. Previously, the system only supported Visa and MasterCard.
                        Moreover, Hireya’s team extended the post‐terminal functionality, which was one of the client’s
                        key requirements, and implemented the application of web hooks to effectively capture data from
                        merchant applications.
                      </p>
                    </div>
                    <div className='feature-tab-info pb-4'>
                      <h3>How It Was Done</h3>
                      <p>
                        The payment gateway was developed using a modern set of technologies and frameworks, all built
                        on
                        forward-looking microservice architecture. From the technological standpoint, microservice
                        architecture
                        is preferable as it provides an opportunity to introduce new versions of different services into
                        production
                        independently of each other to increase the functionality, which does not affect other parts of
                        the application.
                        The use of ready-made cloud services also enabled to quickly develop authentication,
                        notification and queuing capabilities.
                      </p>
                      <p>
                        The client considers developing a full-on resource cooperation with Hireya across the whole
                        company (including sub companies),
                        creating a pool of Hireya’s resources in order to allocate people for the client’s projects on
                        demand. At the moment,
                        there are 18 people involved in the project from Hireya, including developers, QA Automation
                        engineers and Project Managers.
                      </p>
                    </div>
                    <div className='feature-tab-info'>
                      <h3>BUSINESS BENEFITS</h3>
                      <ul className='content-list list-unstyled'>
                        <li>
                          The integration with 3D Secure 2.0 allowed the client to comply with the recent regulations.
                          Without this, it would have been impossible to use payment gateway on the client’s market.
                        </li>
                        <li>
                          The integration with American Express expands possibilities for merchants using the client’s
                          payment gateway to accept payments from another major payment system aside from MasterCard and
                          Visa.
                        </li>
                        <li>
                          The new payment gateway brought more flexibility in working with merchants and speeded up the
                          payment processing for both merchants and sub merchants.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*<div className='col-lg-5'>*/}
                  {/*  <img*/}
                  {/*      src='assets/img/screen/widget-12.png'*/}
                  {/*      alt='feature tab'*/}
                  {/*      className='img-fluid mt-4 mt-lg-0 mt-xl-0'*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyFintechServicesDetail;
