import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const FooterOne = ({ footerLight, style, footerGradient }) => {

  const [email, setEmail] = useState('');
  const [submitButtonLabel, setSubmitButtonLabel] = useState('Subscribe');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const body = {
      email,
    };

    try {
      setSubmitButtonLabel('Please wait...');

      const requestPromise = axios.post(
          `https://s7mvanfyy7726dco5yzt3emmsq0zeswn.lambda-url.us-east-1.on.aws/`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
      );

      requestPromise.then(() => {
        setSubmitButtonLabel('Subscribe');
        setEmail('');
      }).catch((error) => {
        // Handle error
        console.error('Error submitting form:', error);
      });

    } catch (error) {
      // Print error
      console.error('Error submitting form:', error);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
      <>
        <footer className='footer-section'>
          <div
              className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
                  footerGradient ? 'bg-gradient' : ''
              }  text-white ptb-120`}
              style={style}
          >
            <div className='container'>
              <div className='row justify-content-between'>
                <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                  <div className='footer-single-col'>
                    <div className='footer-single-col mb-4'>
                      <img
                          src='assets/img/logo-white.png'
                          alt='logo'
                          className='img-fluid logo-white'
                      />
                      <img
                          src='assets/img/logo-color.png'
                          alt='logo'
                          className='img-fluid logo-color'
                      />
                    </div>
                    <p>
                      Our latest news, articles, and resources, we will sent to
                      your inbox weekly.
                    </p>

                    <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex' onSubmit={handleFormSubmit} >
                      <input
                          type='text'
                          className='input-newsletter form-control me-2'
                          placeholder='Enter your email'
                          name='email'
                          required=''
                          autoComplete='off'
                          value={email}
                          onChange={handleChange}
                      />
                      <input
                          type='submit'
                          value={submitButtonLabel}
                          className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                      />
                    </form>
                    {/*<div className='ratting-wrap mt-4'>*/}
                    {/*  <h6 className='mb-0'>10/10 Overall rating</h6>*/}
                    {/*  <ul className='list-unstyled rating-list list-inline mb-0'>*/}
                    {/*    <li className='list-inline-item'>*/}
                    {/*      <i className='fas fa-star text-warning'></i>*/}
                    {/*    </li>*/}
                    {/*    <li className='list-inline-item'>*/}
                    {/*      <i className='fas fa-star text-warning'></i>*/}
                    {/*    </li>*/}
                    {/*    <li className='list-inline-item'>*/}
                    {/*      <i className='fas fa-star text-warning'></i>*/}
                    {/*    </li>*/}
                    {/*    <li className='list-inline-item'>*/}
                    {/*      <i className='fas fa-star text-warning'></i>*/}
                    {/*    </li>*/}
                    {/*    <li className='list-inline-item'>*/}
                    {/*      <i className='fas fa-star text-warning'></i>*/}
                    {/*    </li>*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                  <div className='row'>
                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                      <div className='footer-single-col'>
                        <h3>About</h3>
                        <ul className='list-unstyled footer-nav-list mb-lg-0'>
                          <li>
                            <Link to='/' className='text-decoration-none'>
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to='/privacy-statement' className='text-decoration-none'>
                              Privacy Statement
                            </Link>
                          </li>

                          <li>
                            <Link
                                to='/terms-and-conditions'
                                className='text-decoration-none'
                            >
                              Terms &amp; Conditions
                            </Link>
                          </li>
                          <li>
                            <Link
                                to='/cookie-policy'
                                className='text-decoration-none'
                            >
                              Cookie Policy
                            </Link>
                          </li>
                          <li>
                            <Link
                                to='/accessibility-statement'
                                className='text-decoration-none'
                            >
                              Accessibility Statement
                            </Link>
                          </li>
                        </ul>
                      </div>

                    </div>
                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                      <div className='footer-single-col'>
                        <h3>Our Services</h3>
                        <ul className='list-unstyled footer-nav-list mb-lg-0'>
                          <li>
                            <Link to='/start-up-scaling-innovation' className='text-decoration-none'>
                              Start up scaling &amp; Innovation
                            </Link>
                          </li>
                          <li>
                            <Link to='/intelligent-automation' className='text-decoration-none'>
                              Intelligent Automation
                            </Link>
                          </li>
                          <li>
                            <Link to='/data-analytics' className='text-decoration-none'>
                              Data &amp; Analytics
                            </Link>
                          </li>
                          <li>
                            <Link to='/product-engineering' className='text-decoration-none'>
                              Product Engineering
                            </Link>
                          </li>
                          {/*<li>*/}
                          {/*  <Link to='/pricing' className='text-decoration-none'>*/}
                          {/*    Pricing*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link to='/blogs' className='text-decoration-none'>*/}
                          {/*    Blog*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/blog-single'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Blog Details*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/contact-us'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Contact*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/career-single'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Career Single*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/single-service'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Services Single*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                        </ul>
                      </div>
                    </div>
                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                      <div className='footer-single-col'>
                        <h3>Company</h3>
                        <ul className='list-unstyled footer-nav-list mb-lg-0'>
                          <li>
                            <Link to='/about-us' className='text-decoration-none'>
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link to='/about-us' className='text-decoration-none'>
                              Locations
                            </Link>
                          </li>
                          <li>
                            <Link to='/blogs' className='text-decoration-none'>
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link to='/career' className='text-decoration-none'>
                              Careers
                            </Link>
                          </li>
                          <li>
                            <Link to='/contact-us' className='text-decoration-none'>
                              Contact Us
                            </Link>
                          </li>
                          <li>
                            <Link to='/portfolio' className='text-decoration-none'>
                              Case Studies
                            </Link>
                          </li>
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/help-center'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Support*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/help-center-single'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Support Single*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/coming-soon'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Coming Soon*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link to='/login' className='text-decoration-none'>*/}
                          {/*    User Login*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to='/career-single'*/}
                          {/*    className='text-decoration-none'*/}
                          {/*  >*/}
                          {/*    Career Single*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
              className={`footer-bottom ${
                  footerLight ? 'footer-light' : 'bg-dark'
              } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
          >
            <div className='container'>
              <div className='row justify-content-between align-items-center'>
                <div className='col-md-7 col-lg-7'>
                  <div className='copyright-text'>
                    <p className='mb-lg-0 mb-md-0'>
                      &copy; Copyright 2024. Hireya Corporation. All rights reserved.
                    </p>
                  </div>
                </div>
                <div className='col-md-4 col-lg-4'>
                  <div className='footer-single-col text-start text-lg-end text-md-end'>
                    <ul className='list-unstyled list-inline footer-social-list mb-0'>
                      {/*<li className='list-inline-item'>*/}
                      {/*  <Link to={{  pathname: "https://www.linkedin.com/company/hireya" }} target="_blank">*/}
                      {/*    <i className='fab fa-linkedin-in'></i>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li className='list-inline-item'>*/}
                      {/*  <Link to={{  pathname: "https://twitter.com/hireya_it1" }} target="_blank">*/}
                      {/*    <i className='fab fa-twitter'></i>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li className='list-inline-item'>*/}
                      {/*  <Link to={{  pathname: "https://www.facebook.com/hireya11/" }} target="_blank">*/}
                      {/*    <i className='fab fa-facebook-f'></i>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li className='list-inline-item'>*/}
                      {/*  <Link to={{  pathname: "https://www.instagram.com/hireya1/" }} target="_blank">*/}
                      {/*    <i className='fab fa-instagram'></i>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li className='list-inline-item'>*/}
                      {/*  <Link to={{  pathname: "https://github.com/Hireya-IT" }} target="_blank">*/}
                      {/*    <i className='fab fa-github'></i>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li className='list-inline-item'>
                        <a href="https://www.linkedin.com/company/hireya" target="_blank" rel="noreferrer">
                          <i className='fab fa-linkedin-in'></i>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href="https://twitter.com/hireya_it1" target="_blank" rel="noreferrer">
                          <i className='fab fa-twitter'></i>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href="https://www.facebook.com/hireya11/" target="_blank" rel="noreferrer">
                          <i className='fab fa-facebook-f'></i>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href="https://www.instagram.com/hireya1/" target="_blank" rel="noreferrer">
                          <i className='fab fa-instagram'></i>
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href="https://github.com/Hireya-IT" target="_blank" rel="noreferrer">
                          <i className='fab fa-github'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
  );
};

export default FooterOne;
