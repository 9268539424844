import React from 'react';

export default function DigiWhyChoose() {
  return (
    <section className="digi-why pt-120">
      <div className="container">
        <div className="row align-content-center justify-content-between">
          <div className="col-lg-6 col-md-12">
            <div className="digi-why-left">
              <img
                src="assets/img/d-girl-w-p.png"
                className="img-fluid"
                alt="Girl"
              />
              <ul className="list-unstyled d-none d-md-block">
                <li>
                  <img
                    src="assets/img/dg-shape-1.png"
                    className="shadow img-fluid"
                    alt="shape"
                  />
                </li>
                <li>
                  <img
                    src="assets/img/dg-shape-2.png"
                    className="shadow img-fluid"
                    alt="shape"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="digi-why-right pt-4">
              <div>
                <span className="span-arrow">Why Choose Us</span>
                <img
                  src="assets/img/arro-right.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </div>
              <h2>Our Retail Software Development Services</h2>
              <p>
                Optimize your operations, improve your customer experiences, and increase your revenue with our development services, including:
              </p>
              <div>
                <ul className="list-unstyled mb-0 mt-5">
                  <li className="d-flex p-4 align-items-center">
                    {/*<div className="me-3">*/}
                    {/*  <img src="assets/img/icons/dw-i-1.svg" alt="icon" />*/}
                    {/*</div>*/}
                    <div>
                      <h4 className="mb-0">Retail Mobile App Design and Development</h4>
                      <span>
                        Engage with your customers from anywhere and personalize the online shopping experience with a
                        custom-built mobile application for your business
                      </span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-4 align-items-center">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/dw-i-2.svg"*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="icon"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <h4 className="mb-0">Retail Web Development Services and Design</h4>
                      <span>
                        Wow your customers with a sleek, responsive, reliable, website that showcases your products
                        or services and boosts your conversion rate
                      </span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-4 align-items-center">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/dw-i-3.svg"*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="icon"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <h4 className="mb-0">Full-Range Integration</h4>
                      <span>
                        We’ll make sure your solution is seamlessly integrated with your existing systems, including point-of-sale channels,
                        inventory management systems, customer relationship management tools, and more
                      </span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-4 align-items-center">
                    {/*<div className="me-3">*/}
                    {/*  <img*/}
                    {/*    src="assets/img/icons/dw-i-4.svg"*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="icon"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div>
                      <h4 className="mb-0">In-Store Automation</h4>
                      <span>
                        Your solution will allow you to automate your inventory management, pricing, employee scheduling,
                        and other in-store processes to maximize your operational efficiency
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
