import React, {useState} from 'react';
import axios from "axios";

const ContactFormTwo = () => {

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [submitButtonLabel, setSubmitButtonLabel] = useState('Get in Touch');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const body = {
      email, firstName, lastName, phone, message
    };

    try {
      setSubmitButtonLabel('Please wait...');

      const requestPromise = axios.post(
          `https://s7mvanfyy7726dco5yzt3emmsq0zeswn.lambda-url.us-east-1.on.aws/`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
      );

      requestPromise.then(() => {
        setSubmitButtonLabel('Get in Touch');

        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
      }).catch((error) => {
        // Handle error
        console.error('Error submitting form:', error);
      });

    } catch (error) {
      // Print error
      console.error('Error submitting form:', error);
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <>
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>Talk to Our Sales &amp; Marketing Department Team</h2>
                <p>
                  Collaboratively promote client-focused convergence vis-a-vis
                  customer directed alignments via standardized infrastructures.
                </p>
              </div>
              <form className='register-form' onSubmit={handleFormSubmit}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label htmlFor='firstName' className='mb-1'>
                      First name <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='firstName'
                        required
                        placeholder='First name'
                        aria-label='First name'
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 '>
                    <label htmlFor='lastName' className='mb-1'>
                      Last name
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='lastName'
                        placeholder='Last name'
                        aria-label='Last name'
                        value={lastName}
                        onChange={handleLastNameChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='phone' className='mb-1'>
                      Phone <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='phone'
                        required
                        placeholder='Phone'
                        aria-label='Phone'
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='email' className='mb-1'>
                      Email<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        required
                        placeholder='Email'
                        aria-label='Email'
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <label htmlFor='yourMessage' className='mb-1'>
                      Message <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <textarea
                        className='form-control'
                        id='yourMessage'
                        required
                        placeholder='How can we help you?'
                        style={{ height: '120px' }}
                        value={message}
                        onChange={handleMessageChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mt-4' value={submitButtonLabel}>
                  {submitButtonLabel}
                </button>
              </form>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
