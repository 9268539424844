import React from 'react';
import { Link } from 'react-router-dom';

export default function DigiSerives() {
  return (
    <section className="digi-services pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mb-5">
              {/*<div>*/}
              {/*  <span className="span-arrow">Best Digital Agency</span>*/}
              {/*  <img src="assets/img/arro-right.svg" alt="arrow" />*/}
              {/*</div>*/}
              <h2>
                Capabilities
                {/*<span className="text-orange">Media Marketing</span>*/}
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              {/*<img src="assets/img/icons/d-1.svg" alt="icon" />*/}
              <h3 className="h5">Reach out to modern consumers</h3>
              <p>
                Now reaching to your customers becomes easier with our software solutions. This software will notify your existing customers
                and target new customers through text messages, emails and even mobile app notifications.
              </p>
              {/*<Link*/}
              {/*  to="/services"*/}
              {/*  className="read-more-link text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right ms-2"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              {/*<img src="assets/img/icons/d-2.svg" alt="icon" />*/}
              <h3 className="h5">Analyze your sales</h3>
              <p>
                With Hireya's analysis software solution, you will be able to monitor your business in real-time. It helps you to take
                initiatives and financial decisions and allows you to remain updated with the latest information.
                <br/>
                <br/>
              </p>
              {/*<Link*/}
              {/*  to="/services"*/}
              {/*  className="read-more-link text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right ms-2"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              {/*<img src="assets/img/icons/d-3.svg" alt="icon" />*/}
              <h3 className="h5">Simplify your retail operations</h3>
              <p>
                Hireya offers you retail operation software to simplify the process of business and maintain the workflow.
                <br/>
                <br/>
                <br/>
                <br/>
              </p>
              {/*<Link*/}
              {/*  to="/services"*/}
              {/*  className="read-more-link text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right ms-2"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              {/*<img src="assets/img/icons/d-4.svg" alt="icon" />*/}
              <h3 className="h5">Oversee the whole business from headquarters</h3>
              <p>
                Now you can manage the whole business activities, monitor sales, productivity and stock level from your central office.
                <br/>
                <br/>
                <br/>
              </p>
              {/*<Link*/}
              {/*  to="/services"*/}
              {/*  className="read-more-link text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right ms-2"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
