import React from "react";
import { Link } from "react-router-dom";

const CyberService = () => {
  return (
    <section className="cyber-features pt-100 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="section-heading text-center mb-5">
              <h5 className="h6 text-primary">Service</h5>
              <h2>Diversified Ecommerce Development Services</h2>
              <p>
                Avail a full range of Ecommerce Development Services that can change the way your users perceive you as a brand.
                Add an edge to your business with game changing Ecommerce Development:
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-list"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Custom Storefront Implementation</h3>
                <p>
                  Our eCommerce website designing services can help with every unique business requirements. Beyond extending and
                  customizing off-the-shelf solutions, we also excel in building bespoke eCommerce website to address your unique needs
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-cloud"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Theme Design and Integration</h3>
                <p>
                  Skilled designers to match your design needs, branding needs and create a unique user-experience. Be it integration
                  of ready-made template or designing a bespoke theme, we excel your expectations
                  <br/>
                  <br/>
                  <br/>
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-database"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">3rd Party Integration</h3>
                <p>
                  Enjoy benefits of seamless interactions between your eCommerce store and back-office systems like analytics, accounting,
                  CRMs, ERPs, etc. Our developers excel in integrating systems like Salesforce, QuickBooks, MS CRM etc
                  <br/>
                  <br/>
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-door-closed"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Plugin / Module / Extension Development</h3>
                <p>
                  With strong background in bespoke software and eCommerce web development, we are well equipped to develop, modify
                  or extend custom plugins, modules and extensions to fit your special requirements on multiple eCommerce platforms
                  <br/>
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-shield-check"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Store Migration</h3>
                <p>
                  Your existing eCommerce solution is not meeting your growing business needs. Beyond implementing a new system, we also
                  take care of migrating your products, orders, customers, etc. from your previous system
                  <br/>
                  <br/>
                  <br/>
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cyber-single-service bg-white rounded-custom mb-30">
              <div className="feature-icon pb-5 rounded bg-primary-soft text-primary mb-4">
                <i className="far fa-server"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Support and Maintenance</h3>
                <p>
                  Our support and maintenance services help you get free on-going activities like routine back-ups, bulk data updates,
                  ongoing bug-fixes and enhancements. We ensure your website is always up-to-date with consistent performance
                  <br/>
                  <br/>
                  <br/>
                </p>
              </div>
              {/*<Link*/}
              {/*  to="/single-service"*/}
              {/*  className="link-with-icon text-decoration-none"*/}
              {/*>*/}
              {/*  Explore More <i className="far fa-arrow-right"></i>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CyberService;
