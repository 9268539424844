import React from 'react';
import SectionTitle from "../common/SectionTitle";

const StartUpScalingInnovationImgContentSix = () => {
  return (
      <>
        <section className='feature-section ptb-120'>
          <div className='container'>
            <div className='row align-items-lg-center justify-content-between'>
              <div className='col-lg-5 mb-7 mb-lg-0'>
                <div className='mb-4' data-aos='fade-up'>
                  <SectionTitle
                      title='A service launch to suit you.'
                      description='When you start working with Hireya, we look to quickly determine which of our two methodologies works best for you:'
                      leftAlign
                  />
                </div>
                <ul
                    className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                    data-aos='fade-up'
                >
                  <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-fingerprint fa-2x text-primary'></i>
                  </span>
                    <h3 className='h5'>01. The problem-led approach</h3>
                    <p>This approach is for teams who are responding to any number of business challenges, from changing regulation and competitive threats to new consumer trends and challenges in their value chain.

                      We bring founders, CTOs, and engineering heads together with our experts to understand the problem, determine the best solution, and get to work on it collaboratively. This includes working out which products would be the best fit to help you achieve your goals in the context of your specific circumstances.
                    </p>
                  </li>
                  <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-shield-check fa-2x text-primary'></i>
                  </span>
                    <h3 className='h5'>02. The solution-led approach</h3>
                    <p>This approach is for businesses who know their problem, and how they can solve it, but they might need help architecting, designing or simply resourcing a team to build that solution.

                      Our experts bring together the right individuals to tackle each problem. Leveraging market-leading tools, platforms and methodologies, our teams deliver experiences users love whilst bringing full transparency to the development process and minimising delivery risk.
                    </p>
                  </li>
                </ul>
              </div>
              <div className='col-lg-6'>
                <div className='pr-lg-4 position-relative' data-aos='fade-up'>
                  {/* <!--animated shape start--> */}
                  <ul className='position-absolute animate-element parallax-element shape-service z--1 hide-medium'>
                    <li className='layer' data-depth='0.03'>
                      <img
                          src='assets/img/color-shape/image-3.svg'
                          alt='shape'
                          className='img-fluid position-absolute color-shape-1'
                      />
                    </li>
                    <li className='layer' data-depth='0.02'>
                      <img
                          src='assets/img/color-shape/feature-1.svg'
                          alt='shape'
                          className='img-fluid position-absolute color-shape-2 z-5'
                      />
                    </li>
                    <li className='layer' data-depth='0.03'>
                      <img
                          src='assets/img/color-shape/feature-3.svg'
                          alt='shape'
                          className='img-fluid position-absolute color-shape-3'
                      />
                    </li>
                  </ul>
                  {/* <!--animated shape end--> */}
                  <div className='bg-light text-center shadow-sm rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto'>
                    <div className='mb-5'>
                      <h3 className='fw-medium h4'>
                        We deliver best quality app for your business
                      </h3>
                    </div>
                    <div className='position-relative w-75 mx-auto'>
                      <img
                          className='position-relative z-2 w-100 h-auto'
                          src='assets/img/screen/half-iphone.svg'
                          alt=' Description'
                      />
                      <img
                          className='position-absolute half-screen'
                          src='assets/img/screen/app-screen-2.jpg'
                          alt=' Description'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default StartUpScalingInnovationImgContentSix;
