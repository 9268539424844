import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import AccessibilityStatementHeader from "../../components/accessibility-statement/AccessibilityStatementHeader";
import AccessibilityStatementDetail from "../../components/accessibility-statement/AccessibilityStatementDetail";

const AccessibilityStatement = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <AccessibilityStatementHeader />
      <AccessibilityStatementDetail />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default AccessibilityStatement;
