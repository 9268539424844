import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import CtaTwo from '../../components/cta/CtaTwo';
import FeatureTwo from '../../components/features/FeatureTwo';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const CaseStudyRetailAndConsumerServices = () => {
  return (
    <Layout>
      <PageHeader
        title='Case Study – E-commerce Platform Development'
        desc='Smart Custom E-commerce Platform Development'
      />
    </Layout>
  );
};

export default CaseStudyRetailAndConsumerServices;
