import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function PaymentTestimonial() {
    const swiperOption = {
        slidesPerView: 2,
        spaceBetween: 24,
        speed: 1000,
        autoplay: {
            delay: 3000,
        },
        slidesPerGroup: 1,
        loop: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
        },
    }
    return (
        <section className="payment-testimonial bg-white-light ptb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="text-center mb-5">
                            <h2 className="mb-3">We Help You</h2>
                            {/*<p className="m-0">*/}
                            {/*    Globally envisioneer an expanded array of methods of empowerment*/}
                            {/*    and out-of-the-box ideas. Completely deliver open-source strategic*/}
                            {/*    theme.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Swiper {...swiperOption} className='payment-testimonial-slider'>
                            <SwiperSlide>
                                <div
                                    className="single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-book-atlas icon-sm text-black'></i>
                                    <h5 className="mb-3">Protect Your Business & Prevent Fraud</h5>
                                    <p className="mb-3">
                                        Tackle your security concerns and mitigate potential attacks with our banking and financial services.
                                        The Hireya team has solutions to take care of data confidentiality and integrity, authentication,
                                        perimeter security, and access control for large enterprises and SMBs.
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-1.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className="single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-bars-sort icon-sm text-dark'></i>
                                    <h5 className="mb-3">Retain Customer & Employee Focus</h5>
                                    <p className="mb-3">
                                        Build customer-centric apps that keep up with market demands, leveraging systems and data in sync.
                                        Enhance employee productivity and engagement with effective and secure tools to enable work from anywhere.
                                        <br/>
                                        <br/>
                                        <br/>
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-2.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className=" single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-arrows-repeat icon-sm text-break'></i>
                                    <h5 className="mb-3">Stay Competitive with Online Services</h5>
                                    <p className="mb-3">
                                        Optimize your IT budget and provide a better user experience through digital products.
                                        Speed up core banking processes to achieve greater agility with open banking APIs and
                                        automation of your back office with top-notch banking IT services.
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-3.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className=" single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-analytics icon-sm text-primary'></i>
                                    <h5 className="mb-3">Explore New Horizons & Expand</h5>
                                    <p className="mb-3">
                                        Scale to new geographies and emerging markets that are first to embrace digital banking solutions.
                                        With our IT expertise and solution consulting capabilities, we empower you to create flexible
                                        products and services, adapt to market trends, and stay relevant.
                                        <br/>
                                        <br/>
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-3.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className=" single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-arrows-from-line icon-sm text-primary'></i>
                                    <h5 className="mb-3">Manage Regulatory Changes & Compliance</h5>
                                    <p className="mb-3">
                                        Sail through regulatory monitoring and audits with regulatory compliance. We help you detect
                                        inconsistencies and issues in digital payments with regular reporting and real-time analytical
                                        tools to mitigate financial violations.
                                        <br/>
                                        <br/>
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-3.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className=" single-payment-testimonial bg-white py-5 px-4 rounded-custom"
                                >
                                    {/*<img*/}
                                    {/*    src="assets/img/testimonial/quotes-p.svg"*/}
                                    {/*    className="mb-4"*/}
                                    {/*    alt="quotes"*/}
                                    {/*/>*/}
                                    <i className='fal fa-alarm-clock icon-sm text-primary'></i>
                                    <h5 className="mb-3">Upgrade Technical Capabilities to Lead the Field</h5>
                                    <p className="mb-3">
                                        Apply innovation to serve your organization’s purpose by leveraging financial IT services.
                                        Hireya aligns your mission and vision with technical opportunities to drive more revenues,
                                        create new value chains, and deliver top-tier financial services.
                                        <br/>
                                    </p>
                                    {/*<ul className="list-unstyled rating-list list-inline mb-4">*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*    <li className="list-inline-item">*/}
                                    {/*        <i className="fas fa-star text-warning"></i>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<div className="payment-t-author d-flex align-items-center">*/}
                                    {/*    <div className="payment-auth-img me-3">*/}
                                    {/*        <img*/}
                                    {/*            src="assets/img/testimonial/tp-3.png"*/}
                                    {/*            alt="author"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="payment-auth-info">*/}
                                    {/*        <h5 className="h6 m-0">Leslie Alexander</h5>*/}
                                    {/*        <small>Design Director </small>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
