import React from 'react';
import { Link } from 'react-router-dom';

const FooterSocial = () => {
  return (
    <>
      <footer className='footer-section'>
        {/* <!--footer bottom start--> */}
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; Copyright 2024. Hireya Corporation. All rights reserved.
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to={{  pathname: "https://www.linkedin.com/company/hireya" }} target="_blank">*/}
                    {/*    <i className='fab fa-linkedin-in'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to={{  pathname: "https://twitter.com/hireya_it1" }} target="_blank">*/}
                    {/*    <i className='fab fa-twitter'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to={{  pathname: "https://www.facebook.com/hireya11/" }} target="_blank">*/}
                    {/*    <i className='fab fa-facebook-f'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to={{  pathname: "https://www.instagram.com/hireya1/" }} target="_blank">*/}
                    {/*    <i className='fab fa-instagram'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className='list-inline-item'>*/}
                    {/*  <Link to={{  pathname: "https://github.com/Hireya-IT" }} target="_blank">*/}
                    {/*    <i className='fab fa-github'></i>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li className='list-inline-item'>
                      <a href="https://www.linkedin.com/company/hireya" target="_blank" rel="noreferrer">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href="https://twitter.com/hireya_it1" target="_blank" rel="noreferrer">
                        <i className='fab fa-twitter'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href="https://www.facebook.com/hireya11/" target="_blank" rel="noreferrer">
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href="https://www.instagram.com/hireya1/" target="_blank" rel="noreferrer">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href="https://github.com/Hireya-IT" target="_blank" rel="noreferrer">
                        <i className='fab fa-github'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--footer bottom end--> */}
      </footer>
    </>
  );
};

export default FooterSocial;
