import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import StartUpScalingInnovationHeader
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationHeader";
import StartUpScalingInnovationDetail
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationDetail";
import StartUpScalingInnovationHeroFour
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationHeroFour";
import StartUpScalingInnovationImgContentSix
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationImgContentSix";
import StartUpScalingInnovationPromoTwo
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationPromoTwo";
import StartUpScalingInnovationCtaThree
    from "../../components/start-up-scaling-innovation/StartUpScalingInnovationCtaThree";

const StartUpScalingInnovation = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <StartUpScalingInnovationHeroFour />
      <StartUpScalingInnovationImgContentSix />
      <StartUpScalingInnovationCtaThree />
      <StartUpScalingInnovationPromoTwo />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default StartUpScalingInnovation;
