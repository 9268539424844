import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import WhoWeWorkWithHeader from "../../components/who-we-work-with/WhoWeWorkWithHeader";
import WhoWeWorkWithDetail from "../../components/who-we-work-with/WhoWeWorkWithDetail";
import WhoWeWorkWithServices from "../../components/who-we-work-with/WhoWeWorkWithServices";
import FeatureTwo from "../../components/features/FeatureTwo";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import WhoWeWorkWithTabOne from "../../components/who-we-work-with/WhoWeWorkWithTabOne";
import CaseStudyEcommerceServicesDetail from "../../components/case-study-ecommerce/CaseStudyEcommerceServicesDetail";
import CaseStudyEcommerceServices from "../../components/case-study-ecommerce/CaseStudyEcommerceServices";

const CaseStudyEcommerce = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <CaseStudyEcommerceServices />
      <CaseStudyEcommerceServicesDetail />
      {/*<TestimonialTwo bgWhite/>*/}
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default CaseStudyEcommerce;
