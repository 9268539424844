import React from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import PageMeta from "../../components/common/PageMeta";
import FooterOne from "../../layout/Footer/FooterOne";
import HeroTwo from "./HomePage";
import ReviewOne from "../../components/review/ReviewOne";
import FeatureTwo from "../../components/features/FeatureTwo";
import WorkProcessTwo from "../../components/work-process/WorkProcessTwo";
import IntegrationOne from "../../components/integration/IntegrationOne";
import CtaTwo from "../../components/cta/CtaTwo";
// import TestimonialOne from "../../components/testimonial/TestimonialOne";
import LatestBlog from "../../components/blog/LatestBlog";
import FeatureTechnologies from "../../components/features/FeatureTechnologies";
// import CtaFour from "../../components/cta/CtaFour";
// import HeroThree from "../index3/HeroThree";
import HomeDashboardOne from "./HomeDashboardOne";

const HomePageSass = () => {
  return (
    <Layout>
      <PageMeta title="Hireya - Software &amp; IT Solutions" />
      <Navbar />
      <HomeDashboardOne />
      <HeroTwo />
      <ReviewOne />
      <FeatureTechnologies />
      <WorkProcessTwo />
      {/*<TestimonialOne hasSubtitle />*/}
      {/*<IntegrationOne />*/}
      <LatestBlog />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomePageSass;
