import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OffCanvasMenu from './OffCanvasMenu';

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
      <>
        <header
            className={`main-header ${
                navDark ? 'position-absolute ' : ''
            } w-100 `} // use w-100 position-absolute
        >
          <nav
              className={`navbar navbar-expand-xl z-10 ${
                  navDark ? 'navbar-dark' : 'navbar-light'
              } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
          >
            <div className="container d-flex align-items-center justify-content-lg-between position-relative">
              <Link
                  to="/"
                  className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
              >
                <img
                    src="assets/img/logo-white.png"
                    alt="logo"
                    className="img-fluid logo-white"
                />
                <img
                    src="assets/img/logo-color.png"
                    alt="logo"
                    className="img-fluid logo-color"
                />
              </Link>
              <Link
                  className="navbar-toggler position-absolute right-0 border-0"
                  to="#offcanvasWithBackdrop"
                  role="button"
              >
              <span
                  className="far fa-bars"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBackdrop"
                  aria-controls="offcanvasWithBackdrop"
              ></span>
              </Link>

              <div className="collapse navbar-collapse justify-content-center">
                <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                  <li className="nav-item dropdown">
                    <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                      What We Do
                    </Link>
                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                      <div className="dropdown-grid-custom rounded-custom width-full">
                        <div className="dropdown-grid-item radius-right-side bg-light">
                          <h6 className="drop-heading">Consult &amp; Design</h6>
                          <Link to="/product-engineering" className="dropdown-link">
                               <div className="drop-title">Product Engineering</div>
                           </Link>
                          <Link to="/intelligent-automation" className="dropdown-link">
                               <div className="drop-title">Intelligent Automation</div>
                           </Link>
                          <Link to="/data-analytics" className="dropdown-link">
                               <div className="drop-title">Data &amp; Analytics</div>
                           </Link>
                          <Link to="/start-up-scaling-innovation" className="dropdown-link">
                               <div className="drop-title">Start Up Scaling &amp; Innovation</div>
                           </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                      Industries We Serve
                    </Link>
                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                      <div className="dropdown-grid-custom rounded-custom width-full">
                        <div className="dropdown-grid-item radius-right-side bg-light">
                          <h6 className="drop-heading">Categories</h6>
                          <Link to="/fintech" className="dropdown-link">
                              <div className="drop-title">Fintech</div>
                          </Link>
                          <Link to="/retail-consumer" className="dropdown-link">
                              <div className="drop-title">Retail &amp; Consumer</div>
                          </Link>
                          <Link to="/ecommerce" className="dropdown-link">
                              <div className="drop-title">eCommerce</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to="/portfolio" className="nav-link">
                      Portfolio
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                        className="nav-link dropdown-toggle"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                      Insights
                    </Link>
                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                      <div className="dropdown-grid-custom rounded-custom width-full">
                        <div className="dropdown-grid-item radius-right-side bg-light">
                          <h6 className="drop-heading">Featured Content</h6>
                          <Link to="/career" className="dropdown-link">
                              <div className="drop-title">Career</div>
                          </Link>
                          <Link to="/blogs" className="dropdown-link">
                              <div className="drop-title">Blog</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to="/about-us" className="nav-link">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                {/*<Link*/}
                {/*    to="/login"*/}
                {/*    className="btn btn-link text-decoration-none me-2"*/}
                {/*>*/}
                {/*  Sign In*/}
                {/*</Link>*/}
                <Link to="/request-for-contact" className="btn btn-primary">
                  Contact Us
                </Link>
              </div>
              <OffCanvasMenu />
            </div>
          </nav>
        </header>
      </>
  );
};

export default Navbar;
