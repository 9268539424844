import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import HomeSassOne from '../themes/index1/HomeSassOne';
import HomePageSass from "../pages/home/HomePageSass";
import HomeSassTwo from '../themes/index2/HomeSassTwo';
import Services from '../pages/services/Services';
import SingleService from '../pages/services/SingleService';
import Login from '../pages/onboard/Login';
import NotFoundScreen from '../components/others/NotFoundScreen';
import HomeDesktopApp from '../themes/index3/HomeDesktopApp';
import PasswordReset from '../pages/onboard/PasswordReset';
import Pricing from '../pages/Pricing';
import ScrollToTop from '../components/common/ScrollToTop';
import HomeAppLanding from '../themes/index4/HomeAppLanding';
import HomeSoftApplication from '../themes/index5/HomeSoftApplication';
import HomeStartup from '../themes/index6/HomeStartup';
import HomeDataAnalysis from '../themes/index7/HomeDataAnalysis';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ComingSoon from '../pages/ComingSoon';
import Integrations from '../pages/integration/Integrations';
import IntegrationSingle from '../pages/integration/IntegrationSingle';
import Blogs from '../pages/blog/Blogs';
import SingleBlog from '../pages/blog/SingleBlog';
import Career from '../pages/career/Career';
import CareerSingle from '../pages/career/CareerSingle';
import HelpCenter from '../pages/help-center/HelpCenter';
import SingleHelpCenter from '../pages/help-center/SingleHelpCenter';
import Signup from '../pages/onboard/Signup';
import RequestDemo from '../pages/RequestDemo';
import HomeAppTwo from '../themes/index8/HomeAppTwo';
import HomeNine from '../themes/index9/HomeNine';
import HomeTen from '../themes/index10/HomeTen';
import SingleProduct from '../pages/product/SingleProduct';
import SinglePortfolio from '../pages/SinglePortfolio';
import HomeEleven from '../themes/index11/HomeEleven';
import HomeTwelve from '../themes/index12/HomeTwelve';
import HomeThirteen from '../themes/index13/HomeThirteen';
import HomeFourteen from '../themes/index14/HomeFourteen';
import HomeFifteen from '../themes/index15/HomeFifteen';
import ProductEngineering from "../pages/product-engineering/ProductEngineering";
import IntelligentAutomation from "../pages/intelligent-automation/IntelligentAutomation";
import DataAnalytics from "../pages/data-analytics/DataAnalytics";
import StartUpScalingInnovation from "../pages/start-up-scaling-innovation/StartUpScalingInnovation";
import Fintech from "../pages/fintech/Fintech";
import RetailConsumer from "../pages/retail-consumer/RetailConsumer";
import Ecommerce from "../pages/ecommerce/Ecommerce";
import CaseStudies from "../pages/case-studies/CaseStudies";
import WhoWeWorkWith from "../pages/who-we-work-with/WhoWeWorkWith";
import CareerSeniorJavaDeveloper from "../pages/career/CareerSeniorJavaDeveloper";
import CareerJavaDeveloper from "../pages/career/CareerJavaDeveloper";
import CareerSeniorAutomationQA from "../pages/career/CareerSeniorAutomationQA";
import CareerSeniorFullStackDeveloper from "../pages/career/CareerSeniorFullStackDeveloper";
import CareerProductOwner from "../pages/career/CareerProductOwner";
import CareerUiUxProductDesigner from "../pages/career/CareerUiUxProductDesigner";
import PrivacyStatement from "../pages/privacy-statement/PrivacyStatement";
import TermsAndConditions from "../pages/terms-and-conditions/TermsAndConditions";
import CookiePolicy from "../pages/cookie-policy/CookiePolicy";
import AccessibilityStatement from "../pages/accessibility-statement/AccessibilityStatement";
import RequestContact from "../pages/RequestContact";
import CaseStudyFintech from "../pages/case-study-fintech/CaseStudyFintech";
import CaseStudyRetailAndConsumer from "../pages/case-study-retail-and-consumer/CaseStudyRetailAndConsumer";
import CaseStudyEcommerce from "../pages/case-study-ecommerce/CaseStudyEcommerce";
import Portfolio from "../pages/portfolio/Portfolio";

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/*<Route exact path="/" element={<HomeSassOne />} />*/}
        {/*<Route exact path="/home-sass-two" element={<HomeSassTwo />} />*/}
        {/*<Route exact path="/home-desktop-app" element={<HomeDesktopApp />} />*/}
        {/*<Route exact path="/home-app-landing" element={<HomeAppLanding />} />*/}
        {/*<Route exact path="/home-soft-app" element={<HomeSoftApplication />} />*/}
        {/*<Route exact path="/home-startup" element={<HomeStartup />} />*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/home-data-analysis"*/}
        {/*  element={<HomeDataAnalysis />}*/}
        {/*/>*/}
        {/*<Route exact path="/home-nine" element={<HomeNine />} />*/}
        {/*<Route exact path="/home-eleven" element={<HomeEleven />} />*/}
        {/*<Route exact path="/home-thirteen" element={<HomeThirteen />} />*/}
        {/*<Route exact path="/home-fourteen" element={<HomeFourteen />} />*/}
        {/*<Route exact path="/home-fifteen" element={<HomeFifteen />} />*/}
        {/*<Route exact path="/home-twelve" element={<HomeTwelve />} />*/}
        {/*<Route exact path="/home-app-two" element={<HomeAppTwo />} />*/}
        {/*<Route exact path="/home-nine" element={<HomeNine />} />*/}
        {/*<Route exact path="/home-ten" element={<HomeTen />} />*/}
        {/*<Route exact path="/single-product" element={<SingleProduct />} />*/}
        {/*<Route exact path="/portfolio-single" element={<SinglePortfolio />} />*/}
        {/*<Route exact path="/about-us" element={<About />} />*/}
        {/*<Route exact path="/services" element={<Services />} />*/}
        {/*<Route exact path="/single-service" element={<SingleService />} />*/}
        {/*<Route exact path="/contact-us" element={<Contact />} />*/}
        {/*<Route exact path="/pricing" element={<Pricing />} />*/}
        {/*<Route exact path="/blogs" element={<Blogs />} />*/}
        {/*<Route exact path="/blog-single" element={<SingleBlog />} />*/}
        {/*<Route exact path="/career" element={<Career />} />*/}
        {/*<Route exact path="/career-single" element={<CareerSingle />} />*/}
        {/*<Route exact path="/help-center" element={<HelpCenter />} />*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/help-center-single"*/}
        {/*  element={<SingleHelpCenter />}*/}
        {/*/>*/}
        {/*<Route exact path="/password-reset" element={<PasswordReset />} />*/}
        {/*<Route exact path="/login" element={<Login />} />*/}
        {/*<Route exact path="/signup" element={<Signup />} />*/}
        {/*<Route exact path="/request-for-demo" element={<RequestDemo />} />*/}
        {/*<Route exact path="/coming-soon" element={<ComingSoon />} />*/}
        {/*<Route exact path="/integrations" element={<Integrations />} />*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/integration-single"*/}
        {/*  element={<IntegrationSingle />}*/}
        {/*/>*/}
        {/*<Route element={<NotFoundScreen />} />*/}

        <Route exact path="/" element={<HomePageSass />} />
        <Route exact path="/product-engineering" element={<ProductEngineering />} />
        <Route exact path="/intelligent-automation" element={<IntelligentAutomation />} />
        <Route exact path="/data-analytics" element={<DataAnalytics />} />
        <Route exact path="/start-up-scaling-innovation" element={<StartUpScalingInnovation />} />
        <Route exact path="/fintech" element={<Fintech />} />
        <Route exact path="/retail-consumer" element={<RetailConsumer />} />
        <Route exact path="/ecommerce" element={<Ecommerce />} />
        <Route exact path="/case-studies" element={<CaseStudies />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/blog-single" element={<SingleBlog />} />
        <Route exact path="/career-senior-java-developer" element={<CareerSeniorJavaDeveloper />} />
        <Route exact path="/career-java-developer" element={<CareerJavaDeveloper />} />
        <Route exact path="/career-senior-automation-qa" element={<CareerSeniorAutomationQA />} />
        <Route exact path="/career-senior-full-stack-developer" element={<CareerSeniorFullStackDeveloper />} />
        <Route exact path="/career-product-owner" element={<CareerProductOwner />} />
        <Route exact path="/career-ui-ux-product-designer" element={<CareerUiUxProductDesigner />} />
        <Route exact path="/career-single" element={<CareerSingle />} />
        <Route exact path="/help-center" element={<HelpCenter />} />
        <Route exact path="/privacy-statement" element={<PrivacyStatement />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route exact path="/request-for-contact" element={<RequestContact />} />
        <Route exact path="/case-study-fintech" element={<CaseStudyFintech />} />
        <Route exact path="/case-study-retail-and-consumer" element={<CaseStudyRetailAndConsumer />} />
        <Route exact path="/case-study-ecommerce" element={<CaseStudyEcommerce />} />


      </Routes>
    </BrowserRouter>
  );
}

export default index;
